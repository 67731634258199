import {useEffect, useRef} from "react";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {Happyar} from "../shared/services/HappyarService";
import {useOrder, useOrderDispatch} from "../OrderContext";
import {VideoModel} from "../shared/models/VideoModel";
import {Survicate} from "../shared/services/SurvicateService";
import {GA4} from "../shared/services/GA4";


export function Record() {
    const navigate = useNavigate();
    const intl = useIntl();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const order = useOrder();
    const orderDispatch = useOrderDispatch();

    useEffect(() => {
        const createVideo = async () => {
            return Happyar.api<VideoModel>({
                method: 'POST',
                path: '/videos',
                data: { order_preset: order.product.presetId },
                fields: ['id']
            });
        };

        if (!order.videoId) {
            createVideo().then(video => {
                orderDispatch({ type: 'setVideoId', payload: video.id });
                Survicate.setVisitorTraits({ videoId: video.id });
            });
        }
    }, [order.videoId, order.product.presetId, orderDispatch]);

    useEffect(() => {
        if (!order.videoId) {
            return;
        }

        const createUIOptions = async () => ({
            dialog: 'record',
            params: {
                videoId: order.videoId
            },
            locale: intl.locale,
            iframe: iframeRef.current!,
            trackingEnabled: true,
            trackingParams: {
                ga4ClientId: await GA4.getField('client_id'),
                ga4SessionId: await GA4.getField('session_id')
            }
        });

       createUIOptions()
           .then(Happyar.ui)
           .then(() => navigate('/order'));

    }, [order.videoId, intl.locale, navigate])

    return (
        <iframe
            title="HappyAR"
            ref={iframeRef}
            style={{ display: 'block', flexGrow: 1, border: 'none' }}
        />
    );
}
