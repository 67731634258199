import {defineMessages, useIntl} from "react-intl";
import {PostalCodeFormData} from "./PostalCodeFormData";
import {ValidationErrors} from "../../models/ValidationErrors";


const messages = defineMessages({
    cityRequired: {
        id: "postal-code-form.error.city-required",
        description: "City required error message",
        defaultMessage: "Enter a city name"
    },
    streetRequired: {
        id: "postal-code-form.error.street-required",
        description: "Street required error message",
        defaultMessage: "Enter a street name"
    },
    houseNumberRequired: {
        id: "postal-code-form.error.house-number-required",
        description: "House number required error message",
        defaultMessage: "Enter a house number"
    }
});

export function usePostalCodeFormValidator() {
    const intl = useIntl();

    const validate = (data: PostalCodeFormData): ValidationErrors => {
        const errors: ValidationErrors = {};

        if (data.city.trim() === '') {
            errors['city'] = intl.formatMessage(messages.cityRequired);
        }

        if (data.street.trim() === '') {
            errors['street'] = intl.formatMessage(messages.streetRequired);
        }

        if (data.houseNumber.trim() === '') {
            errors['houseNumber'] = intl.formatMessage(messages.houseNumberRequired);
        }

        return errors;
    };

    return ({
        validate
    });
}
