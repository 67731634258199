import dayjs, {Dayjs} from "dayjs";
import {ContactModel} from "./shared/models/ContactModel";
import {RecipientModel} from "./shared/models/RecipientModel";
import {firstDeliverableDay} from "./shared/helpers/deliveryHelper";
import { StorageService } from "./shared/services/StorageService";


export interface Product {
    name: string,
    size: string,
    price: number,
    presetId: number
}

export interface OrderState {
    id: string|null;
    key: string|null;
    videoId: string|null;
    lastRecipientId: number;
    product: Product,
    sender: ContactModel|null;
    recipients: RecipientModel[];
    deliveryDate: Dayjs;
    termsAccepted: boolean;
    paymentId: number|null;
}

export type OrderAction =
    | { type: 'setOrderIdAndKey', payload: { id: string, key: string }}
    | { type: 'setVideoId', payload: string }
    | { type: 'setSender', payload: ContactModel }
    | { type: 'setRecipient', payload: RecipientModel }
    | { type: 'addRecipient' }
    | { type: 'deleteRecipient', payload: number }
    | { type: 'setDeliveryDate', payload: Dayjs }
    | { type: 'setTermsAccepted', payload: boolean }
    | { type: 'setPaymentId', payload: number }
    | { type: 'reset' };

export function orderReducer(order: OrderState, action: OrderAction): OrderState {
    switch (action.type) {
        case 'setOrderIdAndKey': {
            return {
                ...order,
                id: action.payload.id,
                key: action.payload.key
            }
        }
        case 'setVideoId': {
            return {
                ...order,
                videoId: action.payload
            };
        }

        case 'setSender': {
            return {
                ...order,
                sender: action.payload
            };
        }

        case 'setRecipient': {
            return {
                ...order,
                recipients: order.recipients.map((recipient) => {
                    return recipient.id === action.payload.id ? action.payload : recipient;
                })
            };
        }

        case 'addRecipient': {
            return {
                ...order,
                lastRecipientId: order.lastRecipientId + 1,
                recipients: [
                    ...order.recipients,
                    { id: order.lastRecipientId + 1, contact: null }
                ]
            }
        }

        case 'deleteRecipient': {
            if (order.recipients.length > 1) {
                return {
                    ...order,
                    recipients: order.recipients.filter(recipient => recipient.id !== action.payload)
                };
            }

            return {
                ...order,
                lastRecipientId: order.lastRecipientId + 1,
                recipients: [{ id: order.lastRecipientId + 1, contact: null }]
            };
        }

        case 'setDeliveryDate': {
            return {
                ...order,
                deliveryDate: action.payload
            };
        }

        case 'setTermsAccepted': {
            return {
                ...order,
                termsAccepted: action.payload
            };
        }

        case 'setPaymentId': {
            return {
                ...order,
                paymentId: action.payload
            }
        }

        case 'reset': {
            return initialOrder;
        }

        default: {
            throw Error('Unknown action');
        }
    }
}

export function orderReviver(this: any, key: string, value: any): any {
    if (key === 'deliveryDate' && value !== null) {
        return dayjs(value);
    }
    return value;
}

export const initialOrder: OrderState = {
    id: null,
    key: null,
    videoId: null,
    lastRecipientId: 1,
    product: {
        name: 'Videokaart',
        size: '15×10 cm',
        price: 5.45,
        presetId: parseInt(process.env.REACT_APP_HAPPYAR_PRESET_ID!)
    },
    sender: getPersistedSender(),
    recipients: [{ id: 1, contact: null }],
    deliveryDate: firstDeliverableDay(),
    termsAccepted: false,
    paymentId: null
};

function getPersistedSender(): ContactModel|null {
    const senderJson = StorageService.getItem('sender');
    return senderJson !== null ? JSON.parse(senderJson) : null;
}
