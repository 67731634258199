import {initSurvicate, getSurvicateInstance} from "@survicate/survicate-web-surveys-wrapper/widget_wrapper";
import {ConfigModel, VisitorAttributes} from "@survicate/survicate-web-surveys-wrapper";


let initialized = false;
let pendingAttributes: VisitorAttributes|null = null;

function init(config: ConfigModel): void {
    initSurvicate(config).then(() => {
        initialized = true;

        if (pendingAttributes) {
            setVisitorTraits(pendingAttributes);
            pendingAttributes = null;
        }
    });
}

function setVisitorTraits(attributes: VisitorAttributes): void {
    const survicate = initialized && getSurvicateInstance();

    if (!survicate) {
        pendingAttributes = attributes;
        return;
    }

    survicate.setVisitorTraits(attributes);
}

export const Survicate = { init, setVisitorTraits };
