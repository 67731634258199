import {Dialog} from "@mui/material";
import {DialogProps} from "@mui/material/Dialog/Dialog";
import {styled} from "@mui/material/styles";


const AbsoluteDialog = styled(Dialog)<DialogProps>(() => ({
    position: 'absolute'
}));

export function NestedDialog(props: DialogProps) {
    return (
        <AbsoluteDialog
            {...props}
            fullScreen
            disablePortal={true}
            hideBackdrop={true}
        />
    );
}

