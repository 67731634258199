import React, {createContext, useContext} from "react";
import {initialOrder, OrderAction, orderReducer, orderReviver, OrderState} from "./OrderReducer";
import {usePersistentReducer} from "./shared/hooks/usePersistentReducer";


const OrderContext = createContext<OrderState>(initialOrder);
const OrderDispatchContext = createContext<React.Dispatch<OrderAction>>(() => null);

interface OrderProviderProps {
    children: React.ReactElement;
}

export function OrderProvider({ children }: OrderProviderProps) {
    const [order, dispatch] = usePersistentReducer('order', orderReviver, orderReducer, initialOrder);

    return (
        <OrderContext.Provider value={order}>
            <OrderDispatchContext.Provider value={dispatch}>
                {children}
            </OrderDispatchContext.Provider>
        </OrderContext.Provider>
    );
}

export function useOrder() {
    return useContext(OrderContext);
}

export function useOrderDispatch() {
    return useContext(OrderDispatchContext);
}
