import {ContactFormData} from "./ContactFormData";
import {ValidationErrors} from "../../models/ValidationErrors";
import {ContactForm} from "./ContactForm";
import React, {ChangeEvent, useEffect} from "react";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {Box, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useAddressSearch} from "../../hooks/useAddressSearch";
import {AddressText} from "../AddressText/AddressText";
import {PostalCodeDialog} from "../PostalCodeDialog/PostalCodeDialog";
import {AddressModel} from "../../models/AddressModel";


const messages = defineMessages({
    noMatchAddress: {
        id: "contact-tab.error.no-match-address",
        description: "Contact tab - No match text",
        defaultMessage: "Combination of postal code and house number is incorrect."
    },
    noMatchPoBox: {
        id: "contact-tab.error.no-match-po-box",
        description: "Contact tab - No match PO box",
        defaultMessage: "Combination of postal code and PO box is incorrect."
    },
    noMatchHouseNumberAddition: {
        id: "contact-tab.error.no-match-house-number-addition",
        description: "Contact tab - No match house number addition text",
        defaultMessage: "Select the house number addition."
    }
});

interface ContactTabProps {
    isSender: boolean;
    isPoBox: boolean;
    data: ContactFormData;
    errors: ValidationErrors;
    onDataPatch: (data: Partial<ContactFormData>) => void;
    onErrorPatch: (errors: ValidationErrors) => void;
}

export function ContactTab({ isSender, isPoBox, data, errors, onDataPatch, onErrorPatch }: ContactTabProps) {
    const intl = useIntl();
    const navigate = useNavigate();

    const { addresses, isSearching } = useAddressSearch({
        postalCode: data.postalCode,
        houseNumber: parseInt(data.houseNumber),
        poBox: parseInt(data.poBox)
    });

    useEffect(() => {
        if (!addresses) {
            onDataPatch({ address: null });
            return;
        }

        const fullMatch = addresses.find(
            (address) => address.houseNumberAddition === data.houseNumberAddition || address.poBox
        ) || null;

        onDataPatch({
            address: fullMatch,
            houseNumberAddition: fullMatch?.houseNumberAddition ?? '',
            houseNumberAdditions: addresses.map(address => address.houseNumberAddition ?? '')
        });

        if (!addresses.length) {
            onErrorPatch({
                address: intl.formatMessage(isPoBox ? messages.noMatchPoBox : messages.noMatchAddress)
            });
            return;
        }

        if (!fullMatch) {
            onErrorPatch({
                address: intl.formatMessage(messages.noMatchHouseNumberAddition)
            });
            return;
        }

        onErrorPatch({
            address: undefined
        });
    }, [isPoBox, addresses, data.houseNumberAddition, onDataPatch, onErrorPatch, intl]);

    const handleDataChange = (key: string, value: string) => {
        onDataPatch({ [key]: value });
        onErrorPatch({ [key]: undefined });
    };

    const handlePersistChange = (event: ChangeEvent<HTMLInputElement>) => {
        onDataPatch({ persist: event.target.checked });
    };

    const handlePostalCodeSearch = () => {
        navigate('postal-code');
    };

    const handlePostalCodeFound = (address: AddressModel) => {
        handleBack();

        onDataPatch({
            postalCode: address.postalCode,
            houseNumber: address.houseNumber?.toString() ?? '',
            houseNumberAddition: address.houseNumberAddition ?? '',
            houseNumberAdditions: [address.houseNumberAddition ?? '']
        });
    };

    const handleBack = () => {
        navigate(-1);
    }

    const tab =
        <Box>
            <ContactForm
                isSender={isSender}
                isPoBox={isPoBox}
                data={data}
                errors={errors}
                onPostalCodeSearch={handlePostalCodeSearch}
                onChange={handleDataChange}
            />
            <AddressText
                address={data.address}
                isSearching={isSearching}
                error={errors.address}
            />
            {isSender &&
                <FormGroup sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.persist}
                                onChange={handlePersistChange}
                            />
                        }
                        label={
                            <FormattedMessage
                                id="contact-tab.persist.label"
                                description="Product list item product name"
                                defaultMessage="Save this data on this device."
                            />
                        }
                    />
                </FormGroup>
            }
            <Outlet />
        </Box>;

    const postalCodeDialog =
        <PostalCodeDialog
            onConfirm={handlePostalCodeFound}
            onClose={handleBack}
        />;

    return (
        <Routes>
            <Route path="/" element={tab}>
                { !isPoBox && <Route path="postal-code" element={postalCodeDialog} /> }
            </Route>
        </Routes>
    );
}
