import {defineMessages, useIntl} from "react-intl";
import {ContactFormData} from "./ContactFormData";
import {ValidationErrors} from "../../models/ValidationErrors";


const messages = defineMessages({
    firstNameRequired: {
        id: "contact-form.error.first-name-required",
        description: "First name required error message",
        defaultMessage: "Enter a first name"
    },
    lastNameRequired: {
        id: "contact-form.error.last-name-required",
        description: "Last name required error message",
        defaultMessage: "Enter a last name"
    },
    postalCodeRequired: {
        id: "contact-form.error.postal-code-required",
        description: "Postal code required error message",
        defaultMessage: "Enter a postal code"
    },
    postalCodeInvalid: {
        id: "contact-form.error.postal-code-invalid",
        description: "Postal code invalid error message",
        defaultMessage: "Enter a valid postal code"
    },
    companyRequired: {
        id: "contact-form.error.company-required",
        description: "Company required error message",
        defaultMessage: "Enter a company name"
    },
    poBoxRequired: {
        id: "contact-form.error.po-box-required",
        description: "PO box required error message",
        defaultMessage: "Enter a PO box"
    },
    houseNumberRequired: {
        id: "contact-form.error.house-number-required",
        description: "House number required error message",
        defaultMessage: "Enter a house number"
    },
    emailRequired: {
        id: "contact-form.error.email-required",
        description: "Email required error message",
        defaultMessage: "Enter an email address"
    },
    emailInvalid: {
        id: "contact-form.error.email-invalid",
        description: "Email invalid error message",
        defaultMessage: "Enter a valid email address"
    }
});

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const postalCodeRegex = /^[1-9][0-9]{3}\s*[A-Za-z]{2}$/;

export function useContactFormValidator() {
    const intl = useIntl();

    const trim = (data: ContactFormData): ContactFormData => {
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
                return [key, typeof value === 'string' ? value.trim() : value];
            })
        ) as ContactFormData;
    };

    const validate = (data: ContactFormData, isSender: boolean, isPoBox: boolean): ValidationErrors => {
        const errors: ValidationErrors = {};
        const trimmed = trim(data);

        if (trimmed.firstName === '') {
            errors['firstName'] = intl.formatMessage(messages.firstNameRequired);
        }
        if (trimmed.lastName === '') {
            errors['lastName'] = intl.formatMessage(messages.lastNameRequired);
        }

        if (trimmed.postalCode === '') {
            errors['postalCode'] = intl.formatMessage(messages.postalCodeRequired);
        } else {
            if (!postalCodeRegex.test(trimmed.postalCode)) {
                errors['postalCode'] = intl.formatMessage(messages.postalCodeInvalid);
            }
        }

        if (isPoBox) {
            if (trimmed.company === '') {
                errors['company'] = intl.formatMessage(messages.companyRequired);
            }
            if (trimmed.poBox === '') {
                errors['poBox'] = intl.formatMessage(messages.poBoxRequired);
            }
        } else {
            if (trimmed.houseNumber === '') {
                errors['houseNumber'] = intl.formatMessage(messages.houseNumberRequired);
            }
        }

        if (isSender) {
            if (trimmed.email === '') {
                errors['email'] = intl.formatMessage(messages.emailRequired);
            } else {
                if (!emailRegex.test(trimmed.email ?? '')) {
                    errors['email'] = intl.formatMessage(messages.emailInvalid);
                }
            }
        }

        return errors;
    };

    return ({
        validate
    });
}
