import {ListItem, ListItemText} from "@mui/material";
import {FormattedMessage} from "react-intl";

export function PaymentListItem() {
    return (
        <ListItem>
            <ListItemText
                primary={
                    <FormattedMessage
                        id="payment-list-item.primary-text"
                        description="Payment list item primary text"
                        defaultMessage="Pay with"
                    />
                }
                secondary={
                    <span style={{display: "flex", alignItems: "center", gap: "0.5em", marginTop: "0.5em", marginBottom: "0.5em"}}>
                        <img style={{height: "2em"}} src="/img/ideal.svg" alt="iDEAL"/>
                        iDEAL
                    </span>
                }
            />
        </ListItem>
    );
}
