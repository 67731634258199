import React from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Stack, Typography} from "@mui/material";
import {Page} from "../Page/Page";
import {PnlAppBar} from "../PnlAppBar/PnlAppBar";

import './ErrorPage.scss';


const messages = defineMessages({
    imageAlt: {
        id: "error-page.image-alt",
        description: "Error page - Image alternative text",
        defaultMessage: "We're sorry"
    }
});

interface ErrorPageProps {
    error?: Error,
}

export function ErrorPage(props: ErrorPageProps) {
    const intl = useIntl();

    const appBar =
        <PnlAppBar
            title={<FormattedMessage
                id="error-page.title"
                description="Error page - Title text"
                defaultMessage="Error"
            />}
        />;

    return (
        <Page
            appBar={appBar}
            fullScreen
        >
            <Stack sx={{ height: '100%'}}
                   direction="column"
                   justifyContent="center"
                   alignItems="center"
                   spacing={4}>

                <img className="error-page-image"
                     src="/img/error.svg"
                     alt={intl.formatMessage(messages.imageAlt)} />

                <Typography variant="body1" align="center">
                    <FormattedMessage
                        id="error-page.message"
                        description="Error page - Unknown error message text"
                        defaultMessage="An unknown error occurred."
                    />
                </Typography>

                {props.error &&
                    <code className="error-page-details">
                        {props.error.stack}
                    </code>
                }

            </Stack>
        </Page>
    );

}
