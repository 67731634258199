import {useRef, useState} from "react";
import {Menu, MenuItem} from "@mui/material";

interface PnlLocaleSelectProps {
    value: string;
    locales?: string[];
    onChange: (locale: string) => void;
}

export function PnlLocaleSelect({value, locales=['nl', 'en'], onChange}: PnlLocaleSelectProps) {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLButtonElement>(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (locale: string) => () => {
        handleClose();
        onChange(locale);
    };

    return (
        <>
            <button
                className="pci-popover__trigger"
                ref={ref}
                onClick={handleOpen}
                data-state={open ? "open" : "closed"}
                aria-controls="locale-menu"
                aria-haspopup="menu"
                aria-expanded={open}
            >
                {value.toUpperCase()}
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    role="presentation">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.624 9.32431L13.1431 17.5719C12.56 18.1388 11.6349 18.1432 11.0464 17.5819L2.41565 9.34896C1.88061 8.83858 1.85929 7.98971 2.36805 7.45295L2.41081 7.40983C2.97545 6.8658 3.86661 6.86307 4.43455 7.40362L12.0996 14.699L19.572 7.41589C20.1318 6.87024 21.0219 6.86756 21.585 7.40983C22.1227 7.9277 22.1402 8.78484 21.624 9.32431Z"
                        fill="currentColor">
                    </path>
                </svg>
            </button>
            <Menu
                id="locale-menu"
                anchorEl={ref.current}
                open={open}
                onClose={handleClose}
            >
                {locales.map(locale =>
                    <MenuItem
                        key={locale}
                        disabled={value === locale}
                        onClick={handleSelect(locale)}
                    >
                        {locale.toUpperCase()}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}
