import React from 'react';
import ReactDOM from 'react-dom/client';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import * as Sentry from "@sentry/react";

import {I18nProvider} from "./I18nProvider";
import {ErrorBoundary} from "./ErrorBoundary";
import App from './App';
import reportWebVitals from './reportWebVitals';

import {GA4} from "./shared/services/GA4";
import {Happyar} from "./shared/services/HappyarService";
import {Survicate} from "./shared/services/SurvicateService";

import themeOptions from "./theme.json";

import './index.scss';


GA4.init(process.env.REACT_APP_GA4_MEASUREMENT_ID!);

Survicate.init({
    workspaceKey: process.env.REACT_APP_SURVICATE_WORKSPACE_KEY!
});

Happyar.init({
    accessToken: process.env.REACT_APP_HAPPYAR_ACCESS_TOKEN!,
    apiBaseUrl: process.env.REACT_APP_HAPPYAR_API_BASE_URL,
    apiVersion: process.env.REACT_APP_HAPPYAR_API_VERSION
});

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new Sentry.Replay()
    ]
});

const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <I18nProvider>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </I18nProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
