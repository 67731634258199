import {useEffect, useState} from "react";
import {AddressService} from "../services/AddressService";
import {AddressModel} from "../models/AddressModel";


export function useAddressSearch(query: Partial<AddressModel>, debounce = 1000) {
    const [addresses, setAddresses] = useState<AddressModel[]|null>(null);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const city = query.city?.trim();
    const street = query.street?.trim();
    const postalCode = query.postalCode?.trim();
    const houseNumber = query.houseNumber;
    const houseNumberAddition = query.houseNumberAddition?.trim();
    const poBox = query.poBox;

    useEffect(() => {
        let isEffectActive = true;
        setAddresses(null);

        if (!(postalCode && houseNumber) &&
            !(city && street && houseNumber) &&
            !(postalCode && poBox) &&
            !(city && poBox))
        {
            setIsSearching(false);
            return;
        }

        setIsSearching(true);

        const timerId = setTimeout(async () => {
            const addresses = await AddressService.get({city, street, postalCode, houseNumber, houseNumberAddition, poBox }) as AddressModel[];
            if (!isEffectActive) {
                return;
            }

            setIsSearching(false);
            setAddresses(addresses);
        }, debounce);

        return () => {
            clearTimeout(timerId);
            isEffectActive = false;
        };

    }, [city, street, postalCode, houseNumber, houseNumberAddition, poBox, debounce]);

    return { addresses, isSearching };
}
