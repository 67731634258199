import {StorageService} from "../services/StorageService";


/**
 * Get the preferred locale
 *
 * @param defaultLocale
 *     The default locale.
 * @param locales
 *     All supported locales.
 * @return
 *     The first supported locale that best matches the user's preferences,
 *     or the default locale if no matching supported locale is found.
 */
export function getPreferredLocale(defaultLocale: string, locales: string[]): string {
    return getPreferredLocales().find(locale => locales.includes(locale)) || defaultLocale;
}

/**
 * Set the preferred locale
 *
 * @param locale
 *    The preferred locale to store.
 */
export function setPreferredLocale(locale: string): void {
    setStoredLocale(locale);
}

/**
 * Get a list of locales ordered by the user's preference
 *
 * @returns
 *     An array of locales ordered by preference.
 */
function getPreferredLocales(): string[] {
    let locales = [];

    // Start with the stored locale
    const storedLocale = getStoredLocale();
    if (storedLocale !== null) {
        locales.push(storedLocale);
    }

    // Add browser locale preferences
    locales.push(...(window.navigator.languages || [window.navigator.language]));

    // Inject locales without region
    locales = locales.reduce((previous: string[], current: string) => {
        previous.push(current);

        const locale = parseLocale(current);
        if (locale && locale.region) {
            previous.push(locale.language);
        }

        return previous;
    }, []);

    // Remove casing inconsistencies (due to user input and browser differences)
    locales = locales.map(locale => locale.toLowerCase());

    // Return unique locales in order
    return [...new Set(locales)];
}

/**
 * Parse a locale string
 *
 * @param locale
 *    The locale string to parse.
 * @returns
 *    An object containing the language and optional region of the locale, or null if the string cannot
 *    be parsed. Supports 3-part locale strings (e.g. zh-Hant-CN for traditional Chinese in China).
 */
function parseLocale(locale: string): { language: string, region?: string } | null {
    const parts = locale.match(/[^-]+/g);

    if (parts === null) {
        return null;
    }

    if (parts.length === 1) {
        return { language: parts[0] };
    }

    return {
        region: parts.pop(),
        language: parts.join('-')
    };
}

/**
 * Get the stored locale
 *
 * @return
 *     The stored locale, or null if no locale is stored.
 */
function getStoredLocale(): string | null {
    return StorageService.getItem('locale');
}

/**
 * Set the stored locale
 *
 * @param locale
 *     The locale to store.
 */
function setStoredLocale(locale: string) {
    StorageService.setItem('locale', locale);
}
