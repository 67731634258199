import {Button, ListItem, ListItemText} from "@mui/material";
import {FormattedMessage} from "react-intl";

interface AddRecipientListItemProps {
    onClick: () => void;
}

export function AddRecipientListItem({onClick}: AddRecipientListItemProps) {
    return (
        <ListItem divider>
            <ListItemText
                inset
                primary={
                    <Button onClick={onClick} sx={{ marginLeft: '-8px' }}>
                        <FormattedMessage
                            id="add-recipient-list-item.button-label"
                            description="Add recipient list item button label"
                            defaultMessage="Add recipient"
                        />
                    </Button>
                }
            />
        </ListItem>
    );
}
