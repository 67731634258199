import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {Route, Routes, useNavigate} from "react-router-dom";
import {
    CircularProgress,
    DialogContentText,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack
} from "@mui/material";

import {useOrder, useOrderDispatch} from "../OrderContext";
import {AlertDialog} from "../shared/components/AlertDialog/AlertDialog";
import {PnlAppBar} from "../shared/components/PnlAppBar/PnlAppBar";
import {Page} from "../shared/components/Page/Page";
import {PaymentService} from "../shared/services/PaymentService";
import {useAbsoluteUrl} from "../shared/hooks/useAbsoluteUrl";

import {banks} from "./banks";

export function Pay() {
    const [isLoading, setIsLoading] = useState(false);
    const [showPrepareError, setShowPrepareError] = useState(false);

    const navigate = useNavigate();
    const order = useOrder();
    const orderDispatch = useOrderDispatch();

    const returnUrls = {
        returnUrl:       useAbsoluteUrl('/done'),
        returnUrlCancel: useAbsoluteUrl('.'),
        returnUrlReject: useAbsoluteUrl('/pay-status', { search: { status: 'reject' }}),
        returnUrlError:  useAbsoluteUrl('/pay-status', { search: { status: 'error'  }})
    };

    const handleListItemButtonClick = (issuerBic: string) => async () => {
        if (!order.id || !order.key) {
            // TODO: order is gone, redirect user
            return;
        }

        try {
            setIsLoading(true);
            const { id, redirectUrl } = await PaymentService.post(
                order.id, order.key, { ...returnUrls, issuerBic }, ['id', 'redirectUrl']
            );
            if (!id || !redirectUrl) {
                throw new Error('No ID or redirect URL in response.');
            }
            orderDispatch({ type: 'setPaymentId', payload: id });
            window.location.href = redirectUrl;
        } catch {
            setShowPrepareError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrepareErrorClose = () => {
        setShowPrepareError(false);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const appBar =
        <PnlAppBar
            canBack={true}
            onBack={handleBack}
            title={
                <FormattedMessage
                    id="pay.page-title"
                    description="Pay page title"
                    defaultMessage="Select a bank"
                />
            }
        />;

    const bankList =
        <List sx={{ mx: -2 }}>
            {banks.map(bank =>
                <ListItem key={bank.bic} disableGutters>
                    <ListItemButton onClick={handleListItemButtonClick(bank.bic)}>
                        <ListItemIcon>
                            <img src={`/img/banks/${bank.logo}`} alt={bank.name} style={{maxWidth: '32px'}} />
                        </ListItemIcon>
                        <ListItemText primary={bank.name} />
                    </ListItemButton>
                </ListItem>
            )}
        </List>;

    const loadingDialog =
        <AlertDialog open={isLoading}>
            <Stack alignItems="center" spacing={2}>
                <CircularProgress />
                <DialogContentText>
                    <FormattedMessage
                        id="pay.loading.message"
                        description="Pay loading message"
                        defaultMessage="Preparing payment..."
                    />
                </DialogContentText>
            </Stack>
        </AlertDialog>;

    const prepareErrorDialog =
        <AlertDialog
            open={showPrepareError}
            onClose={handlePrepareErrorClose}
            actions={[{
                label: <FormattedMessage
                    id="shared.ok-label"
                    description="OK button label"
                    defaultMessage="Ok"
                />,
                onClick: handlePrepareErrorClose
            }]}
        >
            <DialogContentText>
                <FormattedMessage
                    id="pay.prepare-error.message"
                    description="Pay prepare error message"
                    defaultMessage="We were unable to prepare your payment. Check your internet connection and try again."
                />
            </DialogContentText>
        </AlertDialog>

    const page =
        <Page appBar={appBar}>
            {bankList}
            {loadingDialog}
            {prepareErrorDialog}
        </Page>

    return (
        <Routes>
            <Route path="/" element={ page } />
        </Routes>
    );
}
