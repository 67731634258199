import {ContactDialog} from "../../shared/components/ContactDialog/ContactDialog";
import {useOrder, useOrderDispatch} from "../../OrderContext";
import {ContactModel} from "../../shared/models/ContactModel";

interface SenderDialogProps {
    onClose: () => void;
}

export function SenderDialog({onClose}: SenderDialogProps) {
    const order = useOrder();
    const orderDispatch = useOrderDispatch();

    const handleContactChange = (contact: ContactModel) => {
        orderDispatch({
            type: 'setSender',
            payload: contact
        });
        onClose();
    };

    return (
        <ContactDialog
            contact={order.sender}
            isSender={true}
            onChange={handleContactChange}
            onClose={onClose}
        />
    );
}
