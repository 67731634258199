import {ReactNode, useState} from "react";

import './PnlAccordionItem.scss';

const iconOpened =
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M21.6464 14.6993L13.1081 6.41533C12.5411 5.8654 11.6542 5.86106 11.082 6.40542L2.41278 14.6539C1.88961 15.1517 1.85975 15.9893 2.34608 16.5248L2.40805 16.5885C2.97634 17.1347 3.8607 17.1375 4.43224 16.5949L12.0996 9.31587L19.5741 16.5824C20.1376 17.1303 21.021 17.133 21.5878 16.5885C22.1137 16.0834 22.1399 15.2376 21.6464 14.6993Z"
              fill="currentColor">
        </path>
    </svg>;

const iconClosed =
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M21.624 9.32431L13.1431 17.5719C12.56 18.1388 11.6349 18.1432 11.0464 17.5819L2.41565 9.34896C1.88061 8.83858 1.85929 7.98971 2.36805 7.45295L2.41081 7.40983C2.97545 6.8658 3.86661 6.86307 4.43455 7.40362L12.0996 14.699L19.572 7.41589C20.1318 6.87024 21.0219 6.86756 21.585 7.40983C22.1227 7.9277 22.1402 8.78484 21.624 9.32431Z"
              fill="currentColor">
        </path>
    </svg>;

interface PnlAccordionItemProps {
    title: ReactNode;
    text: ReactNode;
}

export function PnlAccordionItem({title, text}: PnlAccordionItemProps) {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="stamp-accordion-item">
            <h3 className="stamp-accordion-item__header">
                <button aria-expanded={expanded} className="stamp-accordion-item__button" onClick={handleToggle}>
                    <span className="stamp-accordion-item__title">
                        {title}
                    </span>
                    <span className="stamp-accordion-item__icon stamp-accordion-item__icon--end">
                        <div className="stamp-icon stamp-icon--size-s">
                            {expanded ? iconOpened : iconClosed}
                        </div>
                    </span>
                </button>
            </h3>
            <div className="stamp-accordion-item__panel" hidden={!expanded}>
                <div className="rich-text ">
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
}
