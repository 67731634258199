import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {Link} from "react-router-dom";
import {PnlPage} from "../shared/components/PnlPage/PnlPage";


const messages = defineMessages({
    postnlPrivacyStatementUrl: {
        id: "privacy.postnl-privacy-statement-url",
        description: "Privacy page PostNL Privacy Statement URL",
        defaultMessage: "https://www.postnl.nl/en/privacy-statement/"
    }
});

/**
 *
 * Page layout copied from https://www.postnl.nl/privacy-verklaring/, except:
 *
 * - Instead of repeating `<section class="outer"><div class="inner">...</div></section>` for each block (with awfully
 *   large vertical margins), we add top margin to the <h2> elements. The style override has been kept local to this
 *   component, such that the original PostNL stylesheet remains unmodified.
 * - Consequently, the .rich-text class has been moved up in the hierarchy. Matching descendants (ul, h3, p) still
 *   work fine with the original PostNL stylesheet.
 */
export function Privacy() {
    const intl = useIntl();

    return (
        <PnlPage>
            <section className="outer">
                <div className="inner inner-narrow rich-text">
                    <FormattedMessage
                        id="privacy.content"
                        description="Cookies page content"
                        defaultMessage={`
                            <article>
                                <h1>Videokaart Privacy Statement</h1>
                                <pIntro>When sending a video card, you share personal details with us, such as the recipient's address. We are committed to protecting this personal data, and we comply with the laws and regulations regarding the use and retention of this data. We would like to explain to you how we use your personal data. This Privacy Statement exclusively pertains to the Videokaart service. It extends the PostNL Privacy Statement, which can be found <aPostnlPrivacyStatement>here</aPostnlPrivacyStatement>.</pIntro>
                            </article>
                            <h2>What personal data do we process?</h2>
                            <p>Below is a list of (possibly) personal data that we process with the Videokaart service:</p>
                            <ul>
                                <li>Videos and images that you provide to us.</li>
                                <li>Your name, address and email address.</li>
                                <li>The name and address of each video card recipient.</li>
                                <li>Information required to process your payment, such as name, account and/or card details.</li>
                                <li>The IP address, browser version, operating system version, device type and language setting of your computer, tablet or mobile phone.</li>
                                <li>Error logs and replays of sessions in which an error occurred.</li>
                                <li>Answers that you provide to our surveys, which may be linked to your order ID.</li>
                                <li>Data we collect by using cookies. Our <aCookieStatement>Cookie Statement</aCookieStatement> explains what types of cookies we use and why.</li>
                            </ul>
                            <h2>How long do we store your data?</h2>
                            <p>We generally do not retain your data any longer than necessary.</p>
                            <ul>
                                <li>If you provide us with videos or images, but do not complete your order, then we store that data for 30 days. This enables you to complete your order at a later time.</li>
                                <li>Once you complete an order, we retain the associated video and image until 3 months after the delivery date of the card. This enables the recipient to play back your video on the card.</li>
                                <li>Contact details of you and the recipients are stored until 3 months after the delivery date of the card. We use that data to send the card, and to provide support until the video on the card expires.</li>
                                <li>Payment information is stored as long as needed to arrange the payment and to comply with relevant legislation and regulation.</li>
                                <li>Service usage and error logs, including your IP address, are stored up to 24 months. We use this information to analyse errors, prevent fraud, and optimize the service delivery.</li>
                            </ul>
                        `}
                        values={{
                            article: chunks => <article className="article">{chunks}</article>,
                            pIntro: chunks => <p className="article__intro intro">{chunks}</p>,
                            aPostnlPrivacyStatement: chunks => <a href={intl.formatMessage(messages.postnlPrivacyStatementUrl)} target="_blank" rel="noreferrer">{chunks}</a>,
                            aCookieStatement: chunks => <Link to="/cookies">{chunks}</Link>
                        }}
                    />
                </div>
            </section>
        </PnlPage>
    );
}
