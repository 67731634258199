import React, {useEffect, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Button} from "@mui/material";

import {PnlAppBar} from "../PnlAppBar/PnlAppBar";
import {Page} from "../Page/Page";
import {PostalCodeForm} from "./PostalCodeForm";
import {PostalCodeFormData} from "./PostalCodeFormData";
import {ValidationErrors} from "../../models/ValidationErrors";
import {usePostalCodeFormValidator} from "./usePostalCodeFormValidator";
import {AddressText} from "../AddressText/AddressText";
import {useAddressSearch} from "../../hooks/useAddressSearch";
import {AddressModel} from "../../models/AddressModel";
import {NestedDialog} from "../NestedDialog/NestedDialog";


const messages = defineMessages({
    noMatch: {
        id: "postal-code-dialog.error.no-match",
        description: "Postal code dialog - No match text",
        defaultMessage: "No postal code found. Check the address."
    },
    noMatchHouseNumberAddition: {
        id: "postal-code-dialog.error.no-match-house-number-addition",
        description: "Postal code dialog - No match house number addition text",
        defaultMessage: "Select the house number addition."
    }
});

const defaultData: PostalCodeFormData = {
    city: '',
    street: '',
    houseNumber: '',
    houseNumberAddition: '',
    houseNumberAdditions: []
};

interface PostalCodeDialogProps {
    onConfirm: (address: AddressModel) => void;
    onClose: () => void;
}

export function PostalCodeDialog({onConfirm, onClose}: PostalCodeDialogProps) {
    const intl = useIntl();
    const validator = usePostalCodeFormValidator();

    const [data, setData] = useState<PostalCodeFormData>(defaultData);
    const [errors, setErrors] = useState<ValidationErrors>({});

    const { addresses, isSearching } = useAddressSearch({
        city: data.city,
        street: data.street,
        houseNumber: parseInt(data.houseNumber),
        houseNumberAddition: data.houseNumberAddition
    });

    useEffect(() => {
        if (!addresses) {
            return;
        }

        const houseNumberAddition = data.houseNumberAddition;
        const houseNumberAdditions = addresses.map(address => address.houseNumberAddition ?? '');
        const houseNumberAdditionMatch = houseNumberAdditions.includes(houseNumberAddition);

        setData(data => ({
            ...data,
            houseNumberAddition: houseNumberAdditionMatch ? houseNumberAddition : '',
            houseNumberAdditions: houseNumberAdditions
        }));

        if (!addresses.length) {
            setErrors(errors => ({
                ...errors,
                address: intl.formatMessage(messages.noMatch)
            }));
            return;
        }

        if (!houseNumberAdditionMatch) {
            setErrors(errors => ({
                ...errors,
                address: intl.formatMessage(messages.noMatchHouseNumberAddition)
            }));
            return;
        }

        setErrors(errors => ({
            ...errors,
            address: undefined
        }));
    }, [addresses, data.houseNumberAddition, intl]);

    const address = addresses?.[0];

    const handleDataChange = (key: string, value: string) => {
        setData({ ...data, [key]: value });
        setErrors({ ...errors, [key]: undefined });
    };

    const handleConfirm = () => {
        const newErrors = validator.validate(data);

        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }

        if (address) {
            onConfirm(address);
            return;
        }
    };

    const appBar =
        <PnlAppBar
            canBack={true}
            title={
                <FormattedMessage
                    id="postal-code-dialog.title"
                    description="Postal code dialog title"
                    defaultMessage="Look up postal code"
                />
            }
            action={
                <Button onClick={handleConfirm}>
                    <FormattedMessage
                        id="shared.confirm-label"
                        description="Confirm button label"
                        defaultMessage="Confirm"
                    />
                </Button>
            }
            onBack={onClose}
        />;

    return (
        <NestedDialog open onClose={onClose}>
            <Page appBar={appBar}>
                <PostalCodeForm
                    data={data}
                    errors={errors}
                    onChange={handleDataChange}
                />
                <AddressText
                    address={address}
                    isSearching={isSearching}
                    error={errors.address}
                />
            </Page>
        </NestedDialog>
    );
}
