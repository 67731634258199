import {ApiService} from "./ApiService";
import {OrderModel} from "../models/OrderModel";

function post(data: Partial<OrderModel>, fields?: string[]): Promise<Partial<OrderModel>> {
    return ApiService.post<OrderModel>({ path: 'v1/orders', data, fields });
}

function put(data: Partial<OrderModel>, fields?: string[]): Promise<Partial<OrderModel>> {
    return ApiService.put<OrderModel>({ path: `v1/orders/${data.id}`, key: data.key, data, fields });
}

export const OrderService = { post, put };
