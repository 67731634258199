import {Link, ListItem, ListItemText, Switch} from "@mui/material";
import {ChangeEvent} from "react";
import {FormattedMessage} from "react-intl";

interface TermsListItemProps {
    accepted: boolean;
    onChange: (accepted: boolean) => void;
}

export function TermsListItem({accepted, onChange}: TermsListItemProps) {

    const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <ListItem
            divider
        >
            <ListItemText
                primary={
                    <FormattedMessage
                        id="terms-list-item.primary-text"
                        description="Terms list item primary text"
                        defaultMessage="I agree with the <a>terms and conditions</a> of PostNL."
                        values={{
                            a: (text) => (
                                <Link
                                    href="/docs/Algemene-voorwaarden-Videokaart.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {text}
                                </Link>
                            )
                        }}
                    />
                }
            />
            <Switch
                edge="end"
                checked={accepted}
                onChange={handleSwitchChange}
            />
        </ListItem>
    );
}
