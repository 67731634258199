import {Dispatch, Reducer, ReducerAction, ReducerState, useEffect, useReducer} from "react";


export function usePersistentReducer<R extends Reducer<any, any>>(
    storageKey: string,
    storageReviver: (this: any, key: string, value: any) => any,
    reducer: R,
    initialState: ReducerState<R>
): [ReducerState<R>, Dispatch<ReducerAction<R>>]
{
    const storedJson = getSessionStorageItem(storageKey);
    const storedState = storedJson !== null ? JSON.parse(storedJson, storageReviver) : initialState;

    const [state, dispatch] = useReducer(reducer, storedState);

    useEffect(() => {
        setSessionStorageItem(storageKey, JSON.stringify(state));
    }, [storageKey, state]);

    return [state, dispatch];
}

function getSessionStorageItem(key: string): string | null {
    try {
        return sessionStorage.getItem(key);
    } catch {
        // E.g. Chrome in an iframe with third party cookies disabled
        return null;
    }
}

function setSessionStorageItem(key: string, value: string): void {
    try {
        sessionStorage.setItem(key, value);
    } catch {
        // E.g. Mobile Safari in private mode
    }
}
