import {ListItem, ListItemText} from "@mui/material";
import {FormattedMessage, FormattedNumberParts} from "react-intl";


interface PriceListItemProps {
    price: number;
    currency?: string;
}

export function PriceListItem({price, currency="EUR"}: PriceListItemProps) {

    const formatPrice = (parts: Intl.NumberFormatPart[]) => {
        const fractionIndex = parts.findIndex((part) => part.type === 'fraction');
        const fractionText = parts[fractionIndex].value;
        const preFractionText = parts.slice(0, fractionIndex).map((part) => part.value).join('');
        const postFractionText = parts.slice(fractionIndex + 1).map((part) => part.value).join('');

        return (
            <span>
                {preFractionText}
                <span style={{verticalAlign: "super", fontSize: "xx-small"}}>{fractionText}</span>
                {postFractionText}
            </span>
        );
    };

    return (
        <ListItem divider>
            <ListItemText
                primaryTypographyProps={{
                    color: "secondary",
                    fontWeight: 400
                }}
                primary={
                    <span style={{display: "flex", justifyContent: "space-between", gap: "1em"}}>
                        <FormattedMessage
                            id="price-list-item.primary-text"
                            description="Price list item primary text"
                            defaultMessage="Total (incl. postage and VAT)"
                        />
                        {/* eslint-disable-next-line react/style-prop-object */}
                        <FormattedNumberParts value={price} style="currency" currency={currency}>
                             {formatPrice}
                        </FormattedNumberParts>
                    </span>
                } />
        </ListItem>
    );
}
