import React from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import {Dayjs} from "dayjs";
import {IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";


interface DeliveryListItemProps {
    deliveryDate: Dayjs;
    recipientCount: number,
    onEdit: () => void;
}

export function DeliveryListItem({deliveryDate, recipientCount, onEdit}: DeliveryListItemProps) {
    return (
        <ListItem
            disablePadding
            divider
            secondaryAction={
                <IconButton edge="end" onClick={onEdit}>
                    <EditIcon />
                </IconButton>
            }
        >
            <ListItemButton onClick={onEdit}>
                <ListItemIcon>
                    <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <FormattedMessage
                            id="delivery-list-item.primary-text"
                            description="Delivery list item primary text"
                            defaultMessage="At the {count, plural, =0 {recipient} one {recipient} other {recipients}}"
                            values={{ count: recipientCount }}
                        />
                    }
                    secondary={
                        <FormattedDate
                            value={deliveryDate.toDate()}
                            year="numeric"
                            month="long"
                            day="numeric"
                        />
                    } />
            </ListItemButton>
        </ListItem>
    );
}
