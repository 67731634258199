import React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {useLocation, useNavigate} from "react-router-dom";
import {Video} from "../shared/components/Video/Video";
import {PnlPage} from "../shared/components/PnlPage/PnlPage";
import {OrderDialog} from "./OrderDialog";
import {PnlAccordion} from "../shared/components/PnlAccordion/PnlAccordion";
import {useOrder} from "../OrderContext";

import './Land.scss';


export function Land() {
    const order = useOrder();
    const navigate = useNavigate();
    const location = useLocation();

    const isOpen = location.pathname !== '/';

    const ctaButton =
        <div className="pci-cta__container">
            <button className="stamp-button stamp-button--variant-primary" onClick={() => navigate('/record')}>
                <FormattedMessage
                    id="land.button.label"
                    description="Landing page button label"
                    defaultMessage="Send now"
                />
            </button>
        </div>;

    return (
        <PnlPage className="land">
            <section className="outer">
                <div className="inner">
                    <div className="pci-banner">
                        <div className="pci-banner__content-container">
                            <h1 className="pci-banner__title left">
                                <FormattedMessage
                                    id="land.heading"
                                    description="Landing page heading"
                                    defaultMessage="Send a video card"
                                />
                                <span className="pci-banner__subtitle">
                                   <FormattedMessage
                                       id="land.subheading"
                                       description="Landing page subheading"
                                       defaultMessage="Discover the magic"
                                   />
                                </span>
                            </h1>
                            <div className="pci-banner__image-container right">
                                <div className="pci-spark pci-spark--left"/>
                                <Video
                                    src="/videos/demo.mp4"
                                    loop muted autoPlay playsInline
                                />
                            </div>
                            <div className="left">
                                <div className="pci-banner__body">
                                    <FormattedMessage
                                        id="land.description"
                                        description="Landing page description"
                                        defaultMessage="Let your emotions speak with a personal video message that comes to life on a postcard. In just a few steps you can make a thoughtful gesture that leaves an unforgettable impression."
                                    />
                                </div>
                                {ctaButton}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="outer">
                <div className="inner">
                    <div className="pci-usp-block">
                        <h2 className="pci-usp-block__title">
                            <FormattedMessage
                                id="land.usp.title"
                                description="Landing page USP title"
                                defaultMessage="Why a video card?"
                            />
                        </h2>
                        <ul className="pci-usp-list">
                            <li className="pci-usp-item">
                                <div className="pci-usp-item__content">
                                    <div className="pci-usp-item__image-container">
                                        <img className="pci-picture" src="/img/emotion.svg" alt=""/>
                                    </div>
                                    <div className="pci-usp-item__text">
                                        <h3 className="pci-usp-item__title">
                                            <FormattedMessage
                                                id="land.usp.1.title"
                                                description="Landing page USP 1 title"
                                                defaultMessage="Moving"
                                            />
                                        </h3>
                                        <div className="rich-text">
                                            <FormattedMessage
                                                id="land.usp.1.text"
                                                description="Landing page USP 1 text"
                                                defaultMessage="Video is a powerful way to express emotions, especially from a distance. Share your feelings in a way that words or pictures alone cannot."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="pci-usp-item">
                                <div className="pci-usp-item__content">
                                    <div className="pci-usp-item__image-container">
                                        <img className="pci-picture" src="/img/medal.svg" alt=""/>
                                    </div>
                                    <div className="pci-usp-item__text">
                                        <h3 className="pci-usp-item__title">
                                            <FormattedMessage
                                                id="land.usp.2.title"
                                                description="Landing page USP 2 title"
                                                defaultMessage="Thoughtful"
                                            />
                                        </h3>
                                        <div className="rich-text">
                                            <FormattedMessage
                                                id="land.usp.2.text"
                                                description="Landing page USP 2 text"
                                                defaultMessage="Show that you took the time and effort to record a personal video message. The recipient will feel highly appreciated."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="pci-usp-item">
                                <div className="pci-usp-item__content">
                                    <div className="pci-usp-item__image-container">
                                        <img className="pci-picture" src="/img/magic.svg" alt=""/>
                                    </div>
                                    <div className="pci-usp-item__text">
                                        <h3 className="pci-usp-item__title">
                                            <FormattedMessage
                                                id="land.usp.3.title"
                                                description="Landing page USP 3 title"
                                                defaultMessage="Unforgettable"
                                            />
                                        </h3>
                                        <div className="rich-text">
                                            <FormattedMessage
                                                id="land.usp.3.text"
                                                description="Landing page USP 3 text"
                                                defaultMessage="Create a moment that will amaze the recipient. The recipient will not quickly forget your video that plays on the card like true magic."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        {ctaButton}
                    </div>
                </div>
            </section>
            <section className="outer outer--light-blue-tone">
                <div className="inner">
                    <div className="pci-usp-block pci-how-to__content">
                        <h2 className="pci-usp-block__title">
                            <FormattedMessage
                                id="land.send.title"
                                description="Landing page send title"
                                defaultMessage="How to send a video card?"
                            />
                        </h2>
                        <ul className="pci-usp-list">
                            <li className="pci-usp-item">
                                <div className="pci-usp-item__content">
                                    <div className="pci-usp-item__image-container">
                                        <img className="pci-picture" src="/img/selfie.svg" alt=""/>
                                    </div>
                                    <div className="pci-usp-item__text">
                                        <h3 className="pci-usp-item__title">
                                            <span className="pci-usp-item__step">1</span>
                                            <FormattedMessage
                                                id="land.send.1.title"
                                                description="Landing page send step 1 title"
                                                defaultMessage="Create a video"
                                            />
                                        </h3>
                                        <div className="rich-text">
                                            <FormattedMessage
                                                id="land.send.1.text"
                                                description="Landing page send step 1 text"
                                                defaultMessage="Record a personal video message, or select an existing video from your album."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="pci-usp-item">
                                <div className="pci-usp-item__content">
                                    <div className="pci-usp-item__image-container">
                                        <img className="pci-picture" src="/img/card.svg" alt=""/>
                                    </div>
                                    <div className="pci-usp-item__text">
                                        <h3 className="pci-usp-item__title">
                                            <span className="pci-usp-item__step">2</span>
                                            <FormattedMessage
                                                id="land.send.2.title"
                                                description="Landing page send step 2 title"
                                                defaultMessage="Select the front"
                                            />
                                        </h3>
                                        <div className="rich-text">
                                            <FormattedMessage
                                                id="land.send.2.text"
                                                description="Landing page send step 2 text"
                                                defaultMessage="Personalize the front of the card with your own photo, or select an image from the video."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="pci-usp-item">
                                <div className="pci-usp-item__content">
                                    <div className="pci-usp-item__image-container">
                                        <img className="pci-picture" src="/img/recipient.svg" alt=""/>
                                    </div>
                                    <div className="pci-usp-item__text">
                                        <h3 className="pci-usp-item__title">
                                            <span className="pci-usp-item__step">3</span>
                                            <FormattedMessage
                                                id="land.send.3.title"
                                                description="Landing page send step 3 title"
                                                defaultMessage="Select the recipient"
                                            />
                                        </h3>
                                        <div className="rich-text">
                                            <FormattedMessage
                                                id="land.send.3.text"
                                                description="Landing page send step 3 text"
                                                defaultMessage="Tell us who you want to send the card to, and when it should be delivered at the specified address."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="pci-usp-item">
                                <div className="pci-usp-item__content">
                                    <div className="pci-usp-item__image-container">
                                        <img className="pci-picture" src="/img/money.svg" alt=""/>
                                    </div>
                                    <div className="pci-usp-item__text">
                                        <h3 className="pci-usp-item__title">
                                            <span className="pci-usp-item__step">4</span>
                                            <FormattedMessage
                                                id="land.send.4.title"
                                                description="Landing page send step 4 title"
                                                defaultMessage="Pay"
                                            />
                                        </h3>
                                        <div className="rich-text">
                                            <FormattedMessage
                                                id="land.send.4.text"
                                                description="Landing page send step 4 text"
                                                defaultMessage="Pay your video card with iDEAL (the stamp is included in the price), and we'll send the card on your behalf!"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        {ctaButton}
                    </div>
                </div>
            </section>
            <section className="outer">
                <div className="inner">
                    <div className="columned-layout">
                        <h2 className="columned-layout__title">
                            <FormattedMessage
                                id="land.faq.title"
                                description="Landing page FAQ title"
                                defaultMessage="Any questions?"
                            />
                        </h2>
                        <div className="rich-text columned-layout__intro intro">
                            <FormattedMessage
                                id="land.faq.intro"
                                description="Landing page FAQ intro"
                                defaultMessage="View the frequently asked questions below, or contact us via the button at the bottom right of the screen. We are ready to help!"
                            />
                        </div>
                        <div className="columned-layout__content">
                            <div className="pci-faq-list pci-faq-list--collapsible">
                                <PnlAccordion items={[
                                    {
                                        id: 1,
                                        title:
                                            <FormattedMessage
                                                id="land.faq.1.title"
                                                description="Landing page FAQ 1 title"
                                                defaultMessage="What does a video card cost?"
                                            />,
                                        text:
                                            <FormattedMessage
                                                id="land.faq.1.text"
                                                description="Landing page FAQ 1 text"
                                                defaultMessage="The price of a video card is {price}. This includes postage and VAT."
                                                values={{
                                                    /* eslint-disable-next-line react/style-prop-object */
                                                    price: <FormattedNumber value={order.product.price} style="currency" currency="EUR" />
                                                }}
                                            />
                                    },
                                    {
                                        id: 2,
                                        title:
                                            <FormattedMessage
                                                id="land.faq.2.title"
                                                description="Landing page FAQ 2 title"
                                                defaultMessage="Until when can the recipient view my video?"
                                            />,
                                        text:
                                            <FormattedMessage
                                                id="land.faq.2.text"
                                                description="Landing page FAQ 2 text"
                                                defaultMessage="The recipient can view your video until 3 months after the scheduled card delivery date."
                                            />
                                    },
                                    {
                                        id: 3,
                                        title:
                                            <FormattedMessage
                                                id="land.faq.3.title"
                                                description="Landing page FAQ 3 title"
                                                defaultMessage="What is the maximum duration of a video?"
                                            />,
                                        text:
                                            <FormattedMessage
                                                id="land.faq.3.text"
                                                description="Landing page FAQ 3 text"
                                                defaultMessage="The maximum duration of a video is 1 minute. You'll be able to cut longer videos during the video selection process."
                                            />
                                    }
                                ]} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <OrderDialog open={isOpen}/>
        </PnlPage>
    );
}
