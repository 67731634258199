import {ContactModel} from "../shared/models/ContactModel";
import React from "react";
import {defineMessages, useIntl} from "react-intl";


const messages = defineMessages({
    poBoxPrefix: {
        id: "shared.po-box-prefix",
        description: "PO box prefix",
        defaultMessage: "PO box"
    }
});

interface ContactTextProps {
    contact: ContactModel;
}

export function ContactText({contact}: ContactTextProps) {
    const intl = useIntl();

    const lines = [];

    if (contact.company) {
        lines.push(contact.company);
    }

    if (contact.firstName || contact.particle || contact.lastName) {
        lines.push(
            [contact.firstName, contact.particle, contact.lastName].filter(Boolean).join(' ')
        );
    }

    if (contact.email) {
        lines.push(contact.email);
    }

    if (contact.street) {
        let fullStreet = contact.street;

        if (contact.houseNumber) {
            fullStreet += ' ' + contact.houseNumber
            if (contact.houseNumberAddition) {
                fullStreet += '-' + contact.houseNumberAddition
            }
        }

        lines.push(fullStreet)
    }

    if (contact.poBox) {
        lines.push(intl.formatMessage(messages.poBoxPrefix) + ' ' + contact.poBox);
    }

    if (contact.postalCode || contact.city) {
        lines.push(
            [contact.postalCode, contact.city].filter(Boolean).join(' ')
        );
    }

    return (
        <>
            {lines.map((line, index) =>
                <React.Fragment key={index}>
                    {index > 0 && <br/>}
                    {line}
                </React.Fragment>
            )}
        </>
    );
}
