import React from "react";
import {FormattedMessage} from "react-intl";
import {Link, useLocation} from "react-router-dom";
import {Box, Button, Stack, Typography} from "@mui/material";
import {Page} from "../shared/components/Page/Page";

export function PayStatus() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');

    const message = status === 'reject'
        ? <FormattedMessage
            id="pay-status.message.reject"
            description="Payment status page - Payment rejected text"
            defaultMessage="The payment was refused by your bank."
        />
        : <FormattedMessage
            id="pay-status.message.error"
            description="Payment status page - Payment error text"
            defaultMessage="The payment did not succeed."
        />;

    return (
        <Page fullScreen>
            <Stack sx={{ height: '100%', p: 2 }} spacing={2}>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5" color="secondary" align="center">
                        {message}
                    </Typography>
                </Box>
                <Button variant="contained" component={Link} to="/pay" replace>
                    <FormattedMessage
                        id="pay-status.continue-label"
                        description="Payment status page - Continue button label"
                        defaultMessage="Continue"
                    />
                </Button>
            </Stack>
        </Page>
    );
}
