import {Outlet} from "react-router-dom";
import {Box, Dialog, useMediaQuery, useTheme} from "@mui/material";


interface OrderDialogProps {
    open: boolean;
}

export function OrderDialog({open}: OrderDialogProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="sm"
            open={open}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: '80vh'}}>
                <Outlet />
            </Box>
        </Dialog>
    );
}
