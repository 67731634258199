import {useEffect, useState} from "react";
import {Happyar} from "../services/HappyarService";
import {VideoModel} from "../models/VideoModel";

export function useVideo(id: string|null, fields?: string[]) {
    const [video, setVideo] = useState<Partial<VideoModel>|null>(null);

    useEffect(() => {
        if (!id) {
            setVideo(null);
            return;
        }

        Happyar
            .api<Partial<VideoModel>>({
                method: 'GET',
                path: `/videos/${id}`,
                fields: fields
            })
            .then(setVideo);

    }, [id, fields]);

    return video;
}
