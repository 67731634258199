import {ContactModel} from "../shared/models/ContactModel";
import {Avatar} from "@mui/material";


interface ContactAvatarProps {
    contact: ContactModel|null;
}

export function ContactAvatar({contact}: ContactAvatarProps) {
    const letters: string[] = [];

    if (contact?.firstName.length) {
        letters.push(contact.firstName[0].toUpperCase());
    }

    if (contact?.lastName.length) {
        letters.push(contact.lastName[0].toUpperCase())
    }

    return (
        <Avatar sx={{ width: 32, height: 32, fontSize: 14 }}>
            {letters.join('') || null}
        </Avatar>
    );
}
