import {FormattedMessage} from "react-intl";
import React from "react";
import {PnlPage} from "../shared/components/PnlPage/PnlPage";


/**
 *
 * Page layout copied from https://www.postnl.nl/cookie-verklaring/, except:
 *
 * - Instead of repeating `<section class="outer"><div class="inner">...</div></section>` for each block (with awfully
 *   large vertical margins), we add top margin to the <h2> elements. The style override has been kept local to this
 *   component, such that the original PostNL stylesheet remains unmodified.
 * - Consequently, the .rich-text class has been moved up in the hierarchy. Matching descendants (ul, h3, p) still
 *   work fine with the original PostNL stylesheet.
 */
export function Cookies() {
    return (
        <PnlPage>
            <section className="outer">
                <div className="inner inner-narrow rich-text">
                    <FormattedMessage
                        id="cookies.content"
                        description="Cookies page content"
                        defaultMessage={`
                            <article>
                                <h1>All about cookies</h1>
                                <pIntro>Do you visit the Videokaart website? Then we use cookies. These are small text files that we store on your computer, phone or tablet. The information contained in these files allows us to recognise you when you revisit our website at a future date. For example, we remember your address information, so we will be able to help you better and faster next time.</pIntro>
                            </article>
                            <p>In addition to cookies, there are other technologies that we use to store and read information on your computer, tablet or mobile phone. An example of this is local storage. Since this works in a similar way, we will also refer to them as 'cookies' for the sake of convenience. We use Javascript for placing cookies.</p>
                            <h2>No cookies?</h2>
                            <p>Would you prefer us not to store information about you and your device settings? In that case, you can change this yourself. The changes you make for the Videokaart website do not automatically apply to the PostNL website or our app and vice versa. You will have to set them separately.</p>
                            <h2>Deleting cookies</h2>
                            <p>You can delete cookies in your internet browser, for example in Google Chrome, Edge or Safari. You can also set your Internet browser to receive a notification when a website uses cookies. You can then decide each time whether you want to give permission for this. Do you want to know more about this? Then use the help function in your browser.</p>
                            <h2>What do we use cookies for?</h2>
                            <p>We use cookies for various purposes. On this page you can read what the cookies do and what companies they come from. For some types of cookies we engage other organisations, for example HappyAR. In that case, we refer to that company's website for more information.</p>
                            <p>Please note that we do our best to keep the list of these organisations as up to date as possible. However, cookies may be placed by a company that is not listed. In this case, please contact our customer service. Then we will adjust the overview on this page.</p>
                            <h2>1. Functional cookies</h2>
                            <p>These cookies do not require your permission, as they enable our website to function properly. For example, we can store order details so you do not have to re-enter this information when the page is refreshed.</p>
                            <p>We use cookies to store the following information, among other things:</p>
                            <ul>
                                <li>Order details. This includes the order ID, your contact details, contact details of the recipient(s), and the delivery date.</li>
                                <li>Whether you have accepted the Videokaart terms and conditions.</li>
                                <li>Ongoing payments (so we can later inform you about their status in case your session is interrupted).</li>
                            </ul>
                            <h3>Systems from which we set functional cookies</h3>                            
                            <p><strong>HappyAR</strong><br></br>We use HappyAR to record, store and play your videos. They use cookies to store your language preference.</p>
                            <p><strong>Sentry</strong><br></br>Sentry collects information about errors that occur while you use the Videokaart website. Cookies are used to store information about your current session.</p>
                            <p><strong>Survicate</strong><br></br>We use Survicate to enable you to provide feedback about our service via surveys. The cookies that are used in the process are described <aSurvicate>here</aSurvicate>.</p>
                            <p><strong>Groove</strong><br></br>The Groove Support Widget allows you to search for help and communicate with our Customer Service department. Information on how cookies are used can be found <aGroove>here</aGroove>.</p>
                            <h2>2. Analytical cookies</h2>
                            <p>These cookies are used to measure how you use our website. This information enables us to improve our services, products, and information. For example, we can see which steps you took to provide a video. Then we use that information to improve the usability of our website. We do not require your permission for these cookies, because they improve the performance of the website. We do not share the data we collect with others.</p>
                            <p>We use these cookies to store the following information, among other things:</p>
                            <ul>
                                <li>How long you have been on our website.</li>
                                <li>Which device you use, e.g. a computer, phone or tablet.</li>
                                <li>Through which advertisements you accessed our website.</li>
                                <li>Which internet browser you use.</li>
                            </ul>
                            <p>In addition, we may also test different designs of the website. Some of our visitors will then be shown a modified version of the website. This way we can measure whether this change actually constitutes an improvement. We use the cookies so that you will always see the same version of the website.</p>
                            <h3>Systems from which we set analytical cookies</h3>
                            <p><strong>Google Analytics</strong><br></br>We use Google Analytics cookies to see how you use our website, what pages you view, and what you click while on our website.</p>
                            <p><strong>Hotjar</strong><br></br>We use Hotjar’s cookies to anonymously record the behaviour of visitors to our website, including mouse clicks, scrolls and clicks.</p>
                            <p>This cookie statement is a translation of the Dutch document. In the event of discrepancies, the Dutch version takes precedence.</p>
                        `}
                        values={{
                            h2: chunks => <h2 style={{ marginTop: '40px' }}>{chunks}</h2>,
                            article: chunks => <article className="article">{chunks}</article>,
                            pIntro: chunks => <p className="article__intro intro">{chunks}</p>,
                            aSurvicate: chunks => <a href="https://help.survicate.com/en/articles/1170930-what-sort-of-cookies-does-survicate-use" target="_blank" rel="noreferrer">{chunks}</a>,
                            aGroove: chunks => <a href="https://help.groovehq.com/help/groove-cookies-and-local-storage" target="_blank" rel="noreferrer">{chunks}</a>
                        }}
                    />
                </div>
            </section>
        </PnlPage>
    );
}
