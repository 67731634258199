import {useParams} from "react-router-dom";
import {useOrderDispatch} from "../../OrderContext";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {FormattedMessage} from "react-intl";
import React from "react";


interface DeleteRecipientDialogProps {
    onClose: () => void;
}

export function DeleteRecipientDialog({onClose}: DeleteRecipientDialogProps) {
    const params = useParams();
    const orderDispatch = useOrderDispatch();

    if (params.id === undefined) {
        throw new Error('No recipient ID set');
    }

    const id = parseInt(params.id);

    const handleConfirm = () => {
        onClose();
        orderDispatch({ type: 'deleteRecipient', payload: id });
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
        >
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage
                        id="delete-recipient-dialog.text"
                        description="Delete recipient dialog text"
                        defaultMessage="Are you sure you want to delete this address?"
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage
                        id="shared.cancel-label"
                        description="Cancel button label"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button onClick={handleConfirm}>
                    <FormattedMessage
                        id="shared.delete-label"
                        description="Delete button label"
                        defaultMessage="Delete"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
