import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Button} from "@mui/material";

import {ContactFormData} from "./ContactFormData";
import {ValidationErrors} from "../../models/ValidationErrors";
import {useContactFormValidator} from "./useContactFormValidator";
import {PnlAppBar} from "../PnlAppBar/PnlAppBar";
import {ContactModel} from "../../models/ContactModel";
import {Page} from "../Page/Page";
import {ContactTab} from "./ContactTab";
import {TabPanel} from "../TabPanel/TabPanel";
import {NestedDialog} from "../NestedDialog/NestedDialog";
import {StorageService} from "../../services/StorageService";


const defaultData: ContactFormData = {
    company: '',
    firstName: '',
    particle: '',
    lastName: '',
    email: '',
    locale: 'nl-NL',
    postalCode: '',
    houseNumber: '',
    houseNumberAddition: '',
    houseNumberAdditions: [],
    poBox: '',
    address: null,
    persist: true
};

enum Tab {
    Address = 'address',
    PoBox   = 'poBox'
}

interface ContactDialogProps {
    contact: ContactModel|null,
    isSender: boolean;
    onChange: (contact: ContactModel) => void;
    onClose: () => void;
}

export function ContactDialog({contact, isSender, onChange, onClose}: ContactDialogProps) {
    const validator = useContactFormValidator();

    const [isPoBox, setIsPoBox] = useState(!!contact?.poBox);
    const [data, setData] = useState<{address: ContactFormData, poBox: ContactFormData}>(contactToData(contact));
    const [errors, setErrors] = useState<{address: ValidationErrors, poBox: ValidationErrors}>({address: {}, poBox: {}});

    const handleAddressDataPatch = useCallback((patch: Partial<ContactFormData>) => {
        setData((data) => ({ ...data, address: { ...data.address, ...patch }}));
    }, []);

    const handlePoBoxDataPatch = useCallback((patch: Partial<ContactFormData>) => {
        setData((data) => ({ ...data, poBox: { ...data.poBox, ...patch }}));
    }, []);

    const handleAddressErrorPatch = useCallback((patch: ValidationErrors) => {
        setErrors((errors) => ({ ...errors, address: { ...errors.address, ...patch }}));
    }, []);

    const handlePoBoxErrorPatch = useCallback((patch: ValidationErrors) => {
        setErrors((errors) => ({ ...errors, poBox: { ...errors.poBox, ...patch }}));
    }, []);

    const handleConfirm = () => {
        const index = isPoBox ? 'poBox' : 'address';

        const newErrors = {
            ...validator.validate(data[index], isSender, isPoBox),
            ...{ address: errors[index].address }
        };

        if (Object.values(newErrors).some(error => error !== undefined)) {
            setErrors({ ...errors, [index]: newErrors });
            return;
        }

        if (!data[index].address) {
            return;
        }

        const contact = formDataToContact(data[index]);
        onChange(contact);

        if (isSender) {
            if (contact.persist) {
                StorageService.setItem('sender', JSON.stringify(contact));
            } else {
                StorageService.removeItem('sender');
            }
        }
    };

    const handleTabChange = (value: any) => {
        setIsPoBox(value === Tab.PoBox);
    };

    const title = isSender
        ? <FormattedMessage
            id="contact-dialog.sender-title"
            description="Contact dialog sender title"
            defaultMessage="Sender"
        />
        : <FormattedMessage
            id="contact-dialog.recipient-title"
            description="Contact dialog recipient title"
            defaultMessage="Recipient"
        />;

    const tabs = isSender ? [] : [
        {
            value: Tab.Address,
            label: <FormattedMessage
                id="contact-dialog.tab-address-label"
                description="Contact dialog address tab label"
                defaultMessage="Address"
            />
        },
        {
            value: Tab.PoBox,
            label: <FormattedMessage
                id="contact-dialog.tab-po-box-label"
                description="Contact dialog PO box tab label"
                defaultMessage="PO box"
            />
        }
    ];

    const appBar =
        <PnlAppBar
            canBack={true}
            title={title}
            action={
                <Button onClick={handleConfirm}>
                    <FormattedMessage
                        id="shared.confirm-label"
                        description="Confirm button label"
                        defaultMessage="Confirm"
                    />
                </Button>
            }
            tabs={tabs}
            activeTab={isPoBox ? Tab.PoBox : Tab.Address}
            onTabChange={handleTabChange}
            onBack={onClose}
        />;

    return (
        <NestedDialog open onClose={onClose}>
            <Page appBar={appBar}>
                <TabPanel value={isPoBox} index={false}>
                    <ContactTab
                        isSender={isSender}
                        isPoBox={false}
                        data={data.address}
                        errors={errors.address}
                        onDataPatch={handleAddressDataPatch}
                        onErrorPatch={handleAddressErrorPatch}
                    />
                </TabPanel>
                <TabPanel value={isPoBox} index={true}>
                    <ContactTab
                        isSender={isSender}
                        isPoBox={true}
                        data={data.poBox}
                        errors={errors.poBox}
                        onDataPatch={handlePoBoxDataPatch}
                        onErrorPatch={handlePoBoxErrorPatch}
                    />
                </TabPanel>
            </Page>
        </NestedDialog>
    );
}

function contactToData(contact: ContactModel|null): {address: ContactFormData, poBox: ContactFormData} {
    const isPoBox = !!contact?.poBox;

    const contactData = !contact ? defaultData : {
        company: contact.company ?? '',
        firstName: contact.firstName,
        particle: contact.particle ?? '',
        lastName: contact.lastName,
        email: contact.email ?? '',
        locale: contact.locale,
        postalCode: contact.postalCode,
        houseNumber: contact.houseNumber?.toString() ?? '',
        houseNumberAddition: contact.houseNumberAddition ?? '',
        houseNumberAdditions: [contact.houseNumberAddition ?? ''],
        poBox: contact.poBox?.toString() ?? '',
        address: {
            city: contact.city,
            street: contact.street,
            postalCode: contact.postalCode,
            houseNumber: contact.houseNumber,
            houseNumberAddition: contact.houseNumberAddition,
            poBox: contact.poBox
        },
        persist: contact.persist
    };

    return {
        address : isPoBox ? defaultData : contactData,
        poBox   : isPoBox ? contactData : defaultData
    };
}

function formDataToContact(data: ContactFormData): ContactModel {
    return {
        company: data.company.trim() || null,
        firstName: data.firstName.trim(),
        particle: data.particle.trim() || null,
        lastName: data.lastName.trim(),
        email: data.email?.trim() || null,
        locale: data.locale,
        city: data.address?.city ?? '',
        postalCode: data.address?.postalCode ?? '',
        street: data.address?.street ?? null,
        houseNumber: data.address?.houseNumber ?? null,
        houseNumberAddition: data.address?.houseNumberAddition ?? null,
        poBox: data.address?.poBox ?? null,
        persist: data.persist
    };
}
