interface CallOptions<T> {
    method: string,
    path: string,
    data?: Partial<T>,
    key?: string,
    fields?: string[],
    query?: Record<string, string>
}

async function call<T>(options: CallOptions<T>): Promise<Partial<T> | Partial<T>[]> {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const accessToken = process.env.REACT_APP_API_ACCESS_TOKEN;

    const headers = new Headers({
        'Authorization': 'Basic ' + btoa(`${accessToken}:`),
        'Accept': 'application/json'
    });
    if (options.key) {
        headers.set('Resource-Key', options.key);
    }
    if (options.data) {
        headers.set('Content-Type', 'application/json');
    }

    const search = new URLSearchParams(options.query);
    if (options.fields && options.fields.length) {
        search.set('fields', options.fields.join(','));
    }

    const url = new URL(options.path, baseUrl);
    url.search = search.toString();

    const response = await fetch(url, {
        method: options.method,
        headers: headers,
        body: options.data ? JSON.stringify(options.data) : undefined
    });

    if (!response.ok) {
        throw new Error(`Received error response (${response.status})`);
    }

    return response.json();
}

function index<T>(options: { path: string, query?: Record<string, string>, fields?: string[] }): Promise<Partial<T>[]> {
    return call<T>({ ...options, method: 'GET'}) as Promise<Partial<T>[]>;
}

function post<T>(options: { path: string, data: Partial<T>, key?: string, fields?: string[] }): Promise<Partial<T>> {
    return call<T>({ ...options, method: 'POST' }) as Promise<Partial<T>>;
}

function put<T>(options: { path: string, data: Partial<T>, key?: string, fields?: string[] }): Promise<Partial<T>> {
    return call<T>({ ...options, method: 'PUT' }) as Promise<Partial<T>>;
}

function get<T>(options: { path: string, fields?: string[] }): Promise<Partial<T>> {
    return call<T>({ ...options, method: 'GET'}) as Promise<Partial<T>>;
}

export const ApiService = { index, post, put, get };
