import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {ReactNode} from "react";

interface AlertDialogProps {
    open: boolean;
    title?: ReactNode;
    children: React.ReactNode;
    actions?: {
        label: ReactNode,
        onClick: () => void
    }[];
    onClose?: () => void;
}

export function AlertDialog({open, title, children, actions, onClose}: AlertDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            {title &&
                <DialogTitle>
                    {title}
                </DialogTitle>
            }
            <DialogContent>
                {children}
            </DialogContent>
            {actions && actions.length &&
                <DialogActions>
                    {actions.map((action, index) => (
                        <Button key={index} onClick={action.onClick}>
                            {action.label}
                        </Button>
                    ))}
                </DialogActions>
            }
        </Dialog>
    );
}
