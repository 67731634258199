import {FormattedMessage} from "react-intl";
import {IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ContactModel} from "../shared/models/ContactModel";
import {ContactText} from "./ContactText";
import {ContactAvatar} from "./ContactAvatar";

import EditIcon from "@mui/icons-material/Edit";


interface SenderListItemProps {
    contact: ContactModel|null;
    onEdit: () => void;
}

export function SenderListItem({contact, onEdit}: SenderListItemProps) {
    return (
        <ListItem
            disablePadding
            divider
            secondaryAction={
                <IconButton edge="end" onClick={onEdit}>
                    <EditIcon />
                </IconButton>
            }
        >
            <ListItemButton onClick={onEdit}>
                <ListItemIcon>
                    <ContactAvatar contact={contact} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <FormattedMessage
                            id="sender-list-item.primary-text"
                            description="Sender list item primary text"
                            defaultMessage="Sender"
                        />
                    }
                    secondary={contact
                        ? <ContactText contact={contact} />
                        : <FormattedMessage
                            id="shared.enter-details-text"
                            description="Enter the details text"
                            defaultMessage="Enter the details"
                        />
                    }
                />
            </ListItemButton>
        </ListItem>
    );
}
