import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Land} from "./land/Land";
import {Record} from "./record/Record";
import {Order} from "./order/Order";
import {Pay} from "./pay/Pay";
import {PayStatus} from "./payStatus/PayStatus";
import {Done} from "./done/Done";
import {Cookies} from "./cookies/Cookies";
import {Privacy} from "./privacy/Privacy";
import {OrderProvider} from "./OrderContext";


function App() {
    return (
        <OrderProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={ <Land /> }>
                        <Route path="record/*" element={ <Record /> } />
                        <Route path="order/*" element={ <Order /> } />
                        <Route path="pay/*" element={ <Pay /> } />
                        <Route path="pay-status/*" element={ <PayStatus /> } />
                        <Route path="done/*" element={ <Done /> } />
                    </Route>
                    <Route path="cookies/*" element={ <Cookies /> } />
                    <Route path="privacy/*" element={ <Privacy /> } />
                </Routes>
            </BrowserRouter>
        </OrderProvider>
    );
}

export default App;





