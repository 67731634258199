import {ChangeEvent} from "react";
import {FormattedMessage} from "react-intl";
import {Button, Grid, MenuItem} from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';

import {PnlTextField} from "../PnlTextField/PnlTextField";
import {ValidationErrors} from "../../models/ValidationErrors";
import {ContactFormData} from "./ContactFormData";


interface ContactFormProps {
    isSender: boolean;
    isPoBox: boolean;
    data: ContactFormData;
    errors: ValidationErrors;
    onChange: (key: string, value: string) => void;
    onPostalCodeSearch: () => void;
}

export function ContactForm({ isSender, isPoBox, data, errors, onChange, onPostalCodeSearch }: ContactFormProps) {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.name, event.target.value);
    };

    return (
        <Grid container rowSpacing={0} columnSpacing={1} sx={{alignItems: "center"}}>
            {!isSender &&
                <Grid item xs={12}>
                    <PnlTextField
                        id="company"
                        name="company"
                        variant="standard"
                        maxLength={30}
                        fullWidth
                        label={<FormattedMessage
                            id="contact-form.company-label"
                            description="Company label"
                            defaultMessage="Company"
                        />}
                        helperText={errors.company || <FormattedMessage
                            id="contact-form.company-helper-text"
                            description="Company helper text"
                            defaultMessage="For a healthcare institution, enter the name, department and room number."
                        />}
                        error={!!errors.company}
                        value={data.company}
                        onChange={handleChange}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <PnlTextField
                    id="first-name"
                    name="firstName"
                    variant="standard"
                    maxLength={25}
                    fullWidth
                    label={<FormattedMessage
                        id="contact-form.first-name-label"
                        description="First name label"
                        defaultMessage="First name"
                    />}
                    helperText={errors.firstName}
                    error={!!errors.firstName}
                    value={data.firstName}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <PnlTextField
                    id="particle"
                    name="particle"
                    variant="standard"
                    maxLength={10}
                    fullWidth
                    label={<FormattedMessage
                        id="contact-form.particle-label"
                        description="Particle label"
                        defaultMessage="Particle"
                    />}
                    helperText={errors.particle}
                    error={!!errors.particle}
                    value={data.particle}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <PnlTextField
                    id="last-name"
                    name="lastName"
                    variant="standard"
                    maxLength={35}
                    fullWidth
                    label={<FormattedMessage
                        id="contact-form.last-name-label"
                        description="Last name label"
                        defaultMessage="Last name"
                    />}
                    helperText={errors.lastName}
                    error={!!errors.lastName}
                    value={data.lastName}
                    onChange={handleChange}
                />
            </Grid>
            {isSender &&
                <Grid item xs={12}>
                    <PnlTextField
                        id="email"
                        name="email"
                        variant="standard"
                        type="email"
                        maxLength={255}
                        fullWidth
                        label={<FormattedMessage
                            id="contact-form.email-label"
                            description="Email label"
                            defaultMessage="Email address"
                        />}
                        helperText={errors.email || <FormattedMessage
                            id="contact-form.email-helper-text"
                            description="Email helper text"
                            defaultMessage="We'll send an order confirmation to this email address."
                        />}
                        error={!!errors.email}
                        value={data.email}
                        onChange={handleChange}
                    />
                </Grid>
            }
            {!isSender &&
                <Grid item xs={12}>
                    <PnlTextField
                        id="locale"
                        name="locale"
                        variant="standard"
                        select
                        fullWidth
                        label={<FormattedMessage
                            id="contact-form.locale-label"
                            description="Locale label"
                            defaultMessage="Language"
                        />}
                        helperText={errors.locale || <FormattedMessage
                            id="contact-form.locale-helper-text"
                            description="Locale helper text"
                            defaultMessage="The language of the playback instructions on the card."
                        />}
                        error={!!errors.locale}
                        value={data.locale}
                        onChange={handleChange}
                    >
                        <MenuItem value="nl-NL">
                            <FormattedMessage
                                id="contact-form.locale-value-nl-NL"
                                description="Locale value nl-NL"
                                defaultMessage="Dutch"
                            />
                        </MenuItem>
                        <MenuItem value="en-US">
                            <FormattedMessage
                                id="contact-form.locale-value-en-US"
                                description="Locale value en-US"
                                defaultMessage="English"
                            />
                        </MenuItem>
                    </PnlTextField>
                </Grid>
            }
            <Grid item xs={5}>
                <PnlTextField
                    id="postal-code"
                    name="postalCode"
                    variant="standard"
                    fullWidth
                    label={<FormattedMessage
                        id="contact-form.postal-code-label"
                        description="Postal code label"
                        defaultMessage="Postal code"
                    />}
                    helperText={errors.postalCode}
                    error={!!errors.postalCode}
                    value={data.postalCode}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={7}>
                {!isPoBox &&
                    <Button startIcon={<PlaceIcon/>} onClick={onPostalCodeSearch}>
                        <FormattedMessage
                            id="contact-form.postal-code-search-button-text"
                            description="Postal code search button text"
                            defaultMessage="Look up"
                        />
                    </Button>
                }
            </Grid>
            {!isPoBox &&
                <Grid item xs={5}>
                    <PnlTextField
                        id="house-number"
                        name="houseNumber"
                        variant="standard"
                        type="number"
                        fullWidth
                        label={<FormattedMessage
                            id="contact-form.house-number-label"
                            description="House number label"
                            defaultMessage="House number"
                        />}
                        helperText={errors.houseNumber}
                        error={!!errors.houseNumber}
                        value={data.houseNumber}
                        onChange={handleChange}
                    />
                </Grid>
            }
            {!isPoBox &&
                <Grid item xs={7}>
                    <PnlTextField
                        id="house-number-addition"
                        name="houseNumberAddition"
                        variant="standard"
                        select
                        fullWidth
                        label={<FormattedMessage
                            id="contact-form.house-number-addition-label"
                            description="House number addition label"
                            defaultMessage="Addition"
                        />}
                        helperText={errors.houseNumberAddition}
                        error={!!errors.houseNumberAddition}
                        value={data.houseNumberAddition}
                        onChange={handleChange}
                    >
                        {data.houseNumberAdditions.map(houseNumberAddition =>
                            <MenuItem key={houseNumberAddition} value={houseNumberAddition}>
                                {houseNumberAddition ||
                                    <FormattedMessage
                                        id="contact-form.house-number-addition-none-item"
                                        description="House number addition none item"
                                        defaultMessage="No addition"
                                    />
                                }
                            </MenuItem>
                        )}
                    </PnlTextField>
                </Grid>
            }
            {isPoBox &&
                <Grid item xs={5}>
                    <PnlTextField
                        id="po-box"
                        name="poBox"
                        variant="standard"
                        type="number"
                        fullWidth
                        label={<FormattedMessage
                            id="contact-form.po-box-label"
                            description="PO box label"
                            defaultMessage="PO box"
                        />}
                        helperText={errors.poBox}
                        error={!!errors.poBox}
                        value={data.poBox}
                        onChange={handleChange}
                    />
                </Grid>
            }
        </Grid>
    );
}
