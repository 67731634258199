interface Bank {
    bic: string;
    name: string;
    logo: string;
}

export const banks: Bank[] = [
    {
        bic: 'ABNANL2A',
        name: 'ABN AMRO',
        logo: 'abn-amro.svg'
    },
    {
        bic: 'ASNBNL21',
        name: 'ASN Bank',
        logo: 'asn-bank.svg'
    },
    {
        bic: 'BUNQNL2A',
        name: 'bunq',
        logo: 'bunq.svg'
    },
    {
        bic: 'INGBNL2A',
        name: 'ING',
        logo: 'ing.svg'
    },
    {
        bic: 'KNABNL2H',
        name: 'Knab',
        logo: 'knab.svg'
    },
    {
        bic: 'NTSBDEB1',
        name: 'N26',
        logo: 'n26.svg'
    },
    {
        bic: 'NNBANL2G',
        name: 'Nationale Nederlanden',
        logo: 'nationale-nederlanden.svg'
    },
    {
        bic: 'RABONL2U',
        name: 'Rabobank',
        logo: 'rabobank.svg'
    },
    {
        bic: 'RBRBNL21',
        name: 'RegioBank',
        logo: 'regiobank.svg'
    },
    {
        bic: 'REVOLT21',
        name: 'Revolut',
        logo: 'revolut.svg'
    },
    {
        bic: 'SNSBNL2A',
        name: 'SNS',
        logo: 'sns.svg'
    },
    {
        bic: 'TRIONL2U',
        name: 'Triodos Bank',
        logo: 'triodos.svg'
    },
    {
        bic: 'FVLBNL22',
        name: 'Van Lanschot Kempen',
        logo: 'van-lanschot-kempen.svg'
    },
    {
        bic: 'BITSNL2A',
        name: 'YourSafe',
        logo: 'yoursafe.svg'
    }
];
