import {RelativeRoutingType, To} from "@remix-run/router";
import {useHref} from "react-router-dom";

interface Options {
    relative?: RelativeRoutingType;
    protocol?: 'http'|'https';
    hostname?: string;
    port?: string;
    search?: {[index: string]: string};
    hash?: string;
}

export function useAbsoluteUrl(to: To, options?: Options): string {
    const {
        relative,
        protocol = window.location.protocol,
        hostname = window.location.hostname,
        port = window.location.port,
        search = {},
        hash = ''
    } = options || {};

    const url = new URL(`${protocol.replace(/:$/, '')}://${hostname}`);
    url.port = port;
    url.pathname = useHref(to, { relative: relative })
    url.hash = hash;

    const searchParams = new URLSearchParams(search);
    url.search = searchParams.toString();

    return url.toString();
}
