import {useParams} from "react-router-dom";
import {ContactDialog} from "../../shared/components/ContactDialog/ContactDialog";
import {useOrder, useOrderDispatch} from "../../OrderContext";
import {ContactModel} from "../../shared/models/ContactModel";


interface RecipientDialogProps {
    onClose: () => void;
}

export function RecipientDialog({onClose}: RecipientDialogProps) {
    const params = useParams();
    const order = useOrder();
    const orderDispatch = useOrderDispatch();

    if (params.id === undefined) {
        throw new Error('No recipient ID set');
    }

    const id = parseInt(params.id);
    const recipient = order.recipients.find((recipient) => recipient.id === id);

    if (recipient === undefined) {
        throw new Error(`No recipient with ID ${id}`);
    }

    const handleContactChange = (contact: ContactModel) => {
        orderDispatch({
           type: 'setRecipient',
           payload: { id, contact }
        });
        onClose();
    };

    return (
        <ContactDialog
            contact={recipient.contact}
            isSender={false}
            onChange={handleContactChange}
            onClose={onClose}
        />
    );
}
