import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {nlNL, enUS} from "@mui/x-date-pickers/locales";
import React, {createContext, ReactNode, useContext, useState} from "react";
import {IntlProvider} from "react-intl";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {FormatXMLElementFn} from "intl-messageformat";
import {getPreferredLocale, setPreferredLocale} from "./shared/helpers/localeHelper";

import 'dayjs/locale/nl';
import 'dayjs/locale/en';

import messages_nl from "./i18n/nl.json";
import messages_en from "./i18n/en.json";

const messages: {[key: string]: any} = {
    nl: messages_nl,
    en: messages_en
};

const localeTexts: {[key: string]: any} = {
    nl: nlNL.components.MuiLocalizationProvider.defaultProps.localeText,
    en: enUS.components.MuiLocalizationProvider.defaultProps.localeText
};

const initialLocale = getPreferredLocale('nl', ['nl', 'en']);

const defaultRichTextElements: Record<string, FormatXMLElementFn<ReactNode>> = {
    br: () => <br/>,
    h1: chunks => <h1>{chunks}</h1>,
    h2: chunks => <h2>{chunks}</h2>,
    h3: chunks => <h3>{chunks}</h3>,
    p: chunks => <p>{chunks}</p>,
    ul: chunks => <ul>{chunks}</ul>,
    li: chunks => <li>{chunks}</li>,
    strong: chunks => <strong>{chunks}</strong>
};

interface I18nContextValue {
    locale: string;
    setLocale: (locale: string) => void;
}

const I18nContext = createContext<I18nContextValue>({ locale: 'nl', setLocale: () => {} });

interface I18nProviderProps {
    children: ReactNode;
}

export function I18nProvider({ children }: I18nProviderProps) {
    const [locale, setLocale] = useState(initialLocale);

    setPreferredLocale(locale);

    return (
        <I18nContext.Provider
            value={{ locale, setLocale }}
        >
            <IntlProvider
                locale={locale}
                messages={messages[locale]}
                defaultLocale="en"
                defaultRichTextElements={defaultRichTextElements}
                fallbackOnEmptyString={false}
            >
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                    localeText={localeTexts[locale]}
                >
                    {children}
                </LocalizationProvider>
            </IntlProvider>
        </I18nContext.Provider>
    )
}

export function useI18n() {
    return useContext(I18nContext);
}
