import {useState} from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import {Dayjs} from "dayjs";
import {Button, Dialog, DialogActions, DialogTitle, Typography} from "@mui/material";
import {StaticDatePicker} from "@mui/x-date-pickers";

import {isDeliverableDay, firstDeliverableDay, lastDeliverableDay} from "../../shared/helpers/deliveryHelper";


interface DeliveryDialogProps {
    deliveryDate: Dayjs;
    onChange: (deliveryDate: Dayjs) => void;
    onClose: () => void;
}

export function DeliveryDialog({deliveryDate, onChange, onClose}: DeliveryDialogProps) {
    const [selectedDate, setSelectedDate] = useState<Dayjs>(deliveryDate);

    const handleDateChange = (value: Dayjs|null) => {
        if (value) {
            setSelectedDate(value);
        }
    };

    const handleOkClick = () => {
        onChange(selectedDate);
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
        >
            <DialogTitle component="div" sx={{color: 'primary.contrastText', backgroundColor: 'primary.main'}}>
                <Typography variant="subtitle1" sx={{opacity: '50%'}}>
                    <FormattedDate
                        value={selectedDate.toDate()}
                        year="numeric"
                    />
                </Typography>
                <Typography variant="h4" sx={{marginTop: '-0.2em'}}>
                    <FormattedDate
                        value={selectedDate.toDate()}
                        month="short"
                        weekday="short"
                        day="numeric"
                    />
                </Typography>
            </DialogTitle>
            <StaticDatePicker
                views={['day']}
                disableHighlightToday
                slotProps={{
                    toolbar: { hidden: true },
                    actionBar: { actions: [] }
                }}
                minDate={firstDeliverableDay()}
                maxDate={lastDeliverableDay()}
                shouldDisableDate={(date) => !isDeliverableDay(date)}
                value={selectedDate}
                onChange={handleDateChange}
            />
            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage
                        id="shared.cancel-label"
                        description="Cancel button label"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button onClick={handleOkClick}>
                    <FormattedMessage
                        id="shared.ok-label"
                        description="OK button label"
                        defaultMessage="Ok"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
