import React, {useState} from "react";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {Box, DialogContentText, List} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Dayjs} from "dayjs";

import {useOrder, useOrderDispatch} from "../OrderContext";
import {SenderListItem} from "./SenderListItem";
import {RecipientListItem} from "./RecipientListItem";
import {DeliveryListItem} from "./DeliveryListItem";
import {PriceListItem} from "./PriceListItem";
import {TermsListItem} from "./TermsListItem";
import {AddRecipientListItem} from "./AddRecipientListItem";
import {PaymentListItem} from "./PaymentListItem";
import {ProductListItem} from "./ProductListItem";
import {PnlAppBar} from "../shared/components/PnlAppBar/PnlAppBar";
import {Page} from "../shared/components/Page/Page";
import {DeliveryDialog} from "./delivery/DeliveryDialog";
import {SenderDialog} from "./sender/SenderDialog";
import {RecipientDialog} from "./recipient/RecipientDialog";
import {RecipientModel} from "../shared/models/RecipientModel";
import {DeleteRecipientDialog} from "./recipient/DeleteRecipientDialog";
import {OrderState} from "../OrderReducer";
import {useVideo} from "../shared/hooks/useVideo";
import {OrderModel} from "../shared/models/OrderModel";
import {OrderService} from "../shared/services/OrderService";
import {AlertDialog} from "../shared/components/AlertDialog/AlertDialog";
import {Survicate} from "../shared/services/SurvicateService";
import {useI18n} from "../I18nProvider";


const videoFields = ['target_image'];

export function Order() {
    const [isSaving, setIsSaving] = useState(false);
    const [showSaveError, setShowSaveError] = useState(false);

    const { locale } = useI18n();
    const navigate = useNavigate();
    const order = useOrder();
    const orderDispatch = useOrderDispatch();
    const video = useVideo(order.videoId, videoFields);

    const recipientCount = order.recipients.length;
    const isReady = isOrderReady(order);

    const addRecipient = () => {
        orderDispatch({ type: 'addRecipient' });
    };

    const deleteRecipient = (id: number) => {
        orderDispatch({ type: 'deleteRecipient', payload: id });
    };

    const setDeliveryDate = (deliveryDate: Dayjs) => {
        orderDispatch({ type: 'setDeliveryDate', payload: deliveryDate });
        goBack();
    };

    const setTermsAccepted = (accepted: boolean) => {
        orderDispatch({ type: 'setTermsAccepted',  payload: accepted });
    };

    const handleEditSender = () => {
        navigate('sender');
    };

    const handleEditRecipient = (recipient: RecipientModel) => {
        navigate(`recipient/${recipient.id}`)
    };

    const handleDeleteRecipient = (recipient: RecipientModel) => {
        if (!recipient.contact) {
            deleteRecipient(recipient.id);
        } else {
            navigate(`recipient/${recipient.id}/delete`)
        }
    };

    const handleEditDelivery = () => {
        navigate('delivery');
    };

    const handleNext = async () => {
        try {
            setIsSaving(true);

            const {id, key} = await saveOrder(order, locale);
            orderDispatch({ type: 'setOrderIdAndKey', payload: { id, key } })

            Survicate.setVisitorTraits({ orderId: id });

            navigate('/pay');
        } catch {
            setShowSaveError(true);
        } finally {
            setIsSaving(false);
        }
    };

    const handleCloseSaveError = () => {
        setShowSaveError(false);
    };

    const goBack = () => {
        navigate(-1);
    };

    const appBar =
        <PnlAppBar
            title={
                <FormattedMessage
                    id="order.page-title"
                    description="Order page title"
                    defaultMessage="Order overview"
                />
            }
            canBack={true}
            onBack={goBack}
        />;

    const orderList =
        <List>
            <ProductListItem
                product={order.product}
                video={video}
            />
            <SenderListItem
                contact={order.sender}
                onEdit={handleEditSender}
            />
            {order.recipients.map((recipient) =>
                <RecipientListItem
                    key={recipient.id}
                    contact={recipient.contact}
                    onEdit={() => handleEditRecipient(recipient)}
                    onDelete={() => handleDeleteRecipient(recipient)}
                />
            )}
            <AddRecipientListItem
                onClick={addRecipient}
            />
            <DeliveryListItem
                deliveryDate={order.deliveryDate}
                recipientCount={recipientCount}
                onEdit={handleEditDelivery}
            />
            <PriceListItem
                price={order.product.price * recipientCount}
            />
            <TermsListItem
                accepted={order.termsAccepted}
                onChange={setTermsAccepted}
            />
            <PaymentListItem />
        </List>;

    const nextButton =
        <Box sx={{ mx: -2, px: 2, py: 4, backgroundColor: 'grey.200' }}>
            <LoadingButton
                variant="contained"
                fullWidth
                disabled={!isReady}
                loading={isSaving}
                onClick={handleNext}
            >
                <FormattedMessage
                    id="order.select-bank-label"
                    description="Select bank button label"
                    defaultMessage="Select a bank"
                />
            </LoadingButton>
        </Box>;

    const senderDialog =
        <SenderDialog
            onClose={goBack}
        />;

    const recipientDialog =
        <RecipientDialog
            onClose={goBack}
        />;

    const deleteRecipientDialog =
        <DeleteRecipientDialog
            onClose={goBack}
        />;

    const deliveryDialog =
        <DeliveryDialog
            deliveryDate={order.deliveryDate}
            onChange={setDeliveryDate}
            onClose={goBack}
        />;

    const saveErrorDialog =
        <AlertDialog
            open={showSaveError}
            onClose={handleCloseSaveError}
            actions={[{
                label: <FormattedMessage
                    id="shared.ok-label"
                    description="OK button label"
                    defaultMessage="Ok"
                />,
                onClick: handleCloseSaveError
            }]}
        >
            <DialogContentText>
                <FormattedMessage
                    id="order.save-error.message"
                    description="Order save error message"
                    defaultMessage="We were unable to update your order. Check your internet connection and try again."
                />
            </DialogContentText>
        </AlertDialog>;

    const page =
        <Page appBar={appBar}>
            {orderList}
            {nextButton}
            {saveErrorDialog}
            <Outlet />
        </Page>;

    return (
        <Routes>
            <Route path="/" element={ page }>
                <Route path="sender/*" element={ senderDialog } />
                <Route path="recipient/:id/delete" element = { deleteRecipientDialog } />
                <Route path="recipient/:id/*" element = { recipientDialog } />
                <Route path="delivery" element={ deliveryDialog } />
            </Route>
        </Routes>
    );
}

function isOrderReady(order: OrderState): boolean {
    return (
        order.sender !== null &&
        order.recipients.length > 0 &&
        order.recipients.filter((recipient) => recipient.contact === null).length === 0 &&
        order.deliveryDate !== null &&
        order.termsAccepted
    );
}

function saveOrder(state: OrderState, locale: string): Promise<{id: string, key: string}> {

    /*
     * Set the sender locale here instead of upon sender contact creation. The user may have changed language
     * settings after creating the sender contact, or the user may have persisted the sender contact and changed
     * language settings on a successive order. Now the sender locale is always in sync upon updating the order.
     */
    const sender = state.sender && {
        ...state.sender,
        locale: locale === 'nl' ? 'nl-NL' : 'en-US'
    };

    const data: Partial<OrderModel> = {
        id: state.id || undefined,
        key: state.key || undefined,
        videoId: state.videoId || undefined,
        sender: sender || undefined,
        recipients: state.recipients
            .filter(recipient => recipient.contact !== null)
            .map(recipient => recipient.contact!),
        deliveryDate: state.deliveryDate.format('YYYY-MM-DD')
    };

    const endpoint = data.id && data.key ? OrderService.put : OrderService.post;

    return endpoint(data, ['id', 'key'])
        .then(order => ({
            id: order.id!,
            key: order.key!
        }));
}
