import React from "react";
import {Box, Stack} from "@mui/material";


interface PageProps {
    fullScreen?: boolean;
    appBar?: React.ReactElement;
    children: React.ReactNode;
}

export function Page({ fullScreen, appBar, children }: PageProps) {
    return (
        <Stack sx={{ height: fullScreen ? '100%' : undefined }}>
            {appBar}
            <Box sx={{ p: fullScreen ? 0 : 2 }} flexGrow={1}>
                {children}
            </Box>
        </Stack>
    );
}
