import {ChangeEvent, useState} from "react";
import {InputAdornment, TextField, TextFieldProps} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import {styled} from '@mui/material/styles';


const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-error': {
        color: theme.palette.text.secondary // Ensure the label color does not change in error state
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.Mui-error': {
        color: theme.palette.error.main // Ensure the label color changes when shrunk in error state
    },
    '& .PnlFormHelperText-root': {
        display: 'flex',
        gap: '1em',
        lineHeight: '1.5em', // Decrease overall text field height
        minHeight: '1.5em', // Ensure equal height for text fields without helperText and maxLength (equals lineHeight)
        visibility: 'hidden' // Hide the helper text by default
    },
    '& .Mui-focused .PnlFormHelperText-root': {
        visibility: 'visible' // Show the helper text if the text field is focused
    },
    '& .Mui-error .PnlFormHelperText-root': {
        visibility: 'visible' // Show the helper text if the text field has an error
    },
    '& .PnlFormHelperText-text': {
        flexGrow: 1
    },
    '& .PnlFormHelperText-length': {
        color: theme.palette.text.secondary // Ensure the field length text color doesn't change in error state
    }
}));

type PnlTextFieldProps = TextFieldProps & {
    maxLength?: number;
}

export function PnlTextField(props: PnlTextFieldProps) {

    const {
        error = false,
        helperText,
        inputProps = {},
        InputProps = {},
        maxLength,
        value
    } = props;

    const [length, setLength] = useState(String(value ?? '').length);

    if (maxLength) {
        inputProps.maxLength = maxLength;
    }

    if (error) {
        InputProps.endAdornment = (
            <InputAdornment position="end">
                <ErrorIcon color="error" />
            </InputAdornment>
        )
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLength(event.target.value.length);
        props.onChange?.(event);
    };

    return (
        <StyledTextField
            {...props}
            helperText={
                <span className="PnlFormHelperText-root">
                    <span className="PnlFormHelperText-text">{helperText}</span>
                    {maxLength &&
                        <span className="PnlFormHelperText-length">{length}/{maxLength}</span>
                    }
                </span>
            }
            inputProps={inputProps}
            InputProps={InputProps}
            onChange={handleChange}
        />
    );
}
