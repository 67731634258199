import React from "react";
import {Box} from "@mui/material";


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export function TabPanel({ children, value, index }: TabPanelProps) {
    return (
        <Box hidden={value !== index}>
            {children}
        </Box>
    );
}
