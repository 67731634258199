import React from "react";
import {FormattedMessage} from "react-intl";
import {CircularProgress, Stack, Typography} from "@mui/material";
import {AddressModel} from "../../models/AddressModel";


interface AddressTextProps {
    address?: AddressModel|null;
    isSearching?: boolean;
    error?: string;
}

export function AddressText({address = null, isSearching = false, error}: AddressTextProps) {
    if (isSearching) {
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <CircularProgress size="1em" />
                <Typography variant="body1">
                    <FormattedMessage
                        id="address-text.searching-text"
                        description="Searching text"
                        defaultMessage="Searching for address..."
                    />
                </Typography>
            </Stack>
        );
    }

    if (error) {
        return (
            <Typography variant="caption" color="error">
                {error}
            </Typography>
        );
    }

    if (address) {
        if (address.poBox) {
            return (
                <Typography variant="body1">
                    <FormattedMessage
                        id="shared.po-box-prefix"
                        description="PO box prefix"
                        defaultMessage="PO box"
                    />
                    &nbsp;{address.poBox}<br/>
                    {address.postalCode} {address.city}
                </Typography>
            );
        }

        return (
            <Typography variant="body1">
                {address.street}&nbsp;{address.houseNumber}{address.houseNumberAddition ? '-' + address.houseNumberAddition : ''}<br/>
                {address.postalCode} {address.city}
            </Typography>
        );
    }

    return null;
}
