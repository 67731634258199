import {ReactNode} from "react";
import {PnlAccordionItem} from "./PnlAccordionItem";

interface PnlAccordionProps {
    items: {
        id: number;
        title: ReactNode;
        text: ReactNode
    }[]
}

export function PnlAccordion({items}: PnlAccordionProps) {
    return (
        <div className="stamp-accordion">
            {items.map(item =>
                <PnlAccordionItem key={item.id} title={item.title} text={item.text} />
            )}
        </div>
    );
}
