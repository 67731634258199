import {AppBar, IconButton, Tab, Tabs, Toolbar, Typography} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, {ReactElement, ReactNode} from "react";
import {styled} from "@mui/system";


interface PnlAppBarProps {
    title: ReactNode;
    canBack?: boolean;
    action?: ReactElement;
    tabs?: {
        label: ReactNode;
        value: any;
    }[];
    activeTab?: any;
    onTabChange?: (value: any) => void;
    onBack?: () => void;
}

const PnlAppBarTabs = styled(Tabs, {
    name: 'PnlAppBar',
    slot: 'Tabs',
    overridesResolver: (props, styles) => styles.tabs
})(({ theme }) => ({
    flexGrow: 1,
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.contrastText
    }
}));

export function PnlAppBar(props: PnlAppBarProps) {
    const {
        title,
        canBack = false,
        action,
        tabs = [],
        activeTab = false,
        onTabChange,
        onBack
    } = props;

    return (
        <AppBar position="sticky">
            <Toolbar>
                {canBack &&
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        sx={{ mr: 2 }}
                        onClick={onBack}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                }
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                >
                    {title}
                </Typography>
                {action &&
                    React.cloneElement(action, {
                        color: "inherit"
                    })
                }
            </Toolbar>

            {!!tabs.length &&
                <Toolbar
                    variant="dense"
                    disableGutters={true}
                >
                    <PnlAppBarTabs
                        variant="fullWidth"
                        textColor="inherit"
                        value={activeTab}
                        onChange={(event, value) => onTabChange?.(value)}
                    >
                        {tabs.map(tab =>
                            <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                            />
                        )}
                    </PnlAppBarTabs>
                </Toolbar>
            }
        </AppBar>
    );
}
