import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem
} from "@mui/material";
import {ContactModel} from "../shared/models/ContactModel";
import {ContactAvatar} from "./ContactAvatar";
import {ContactText} from "./ContactText";

import MoreVertIcon from "@mui/icons-material/MoreVert";


interface RecipientListItemProps {
    contact: ContactModel|null;
    onEdit: () => void;
    onDelete: () => void;
}

export function RecipientListItem({contact, onEdit, onDelete}: RecipientListItemProps) {

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleMenuEdit = () => {
        handleMenuClose();
        onEdit();
    };

    const handleMenuDelete = () => {
        handleMenuClose();
        onDelete();
    }

    return (
        <>
            <ListItem
                disablePadding
                divider
                secondaryAction={
                    <IconButton edge="end" onClick={handleMenuOpen}>
                        <MoreVertIcon />
                    </IconButton>
                }
            >
                <ListItemButton onClick={onEdit}>
                    <ListItemIcon>
                        <ContactAvatar contact={contact} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <FormattedMessage
                                id="recipient-list-item.primary-text"
                                description="Recipient list item primary text"
                                defaultMessage="Recipient"
                            />
                        }
                        secondary={contact
                            ? <ContactText contact={contact} />
                            : <FormattedMessage
                                id="shared.enter-details-text"
                                description="Enter the details text"
                                defaultMessage="Enter the details"
                            />
                        }
                    />
                </ListItemButton>
            </ListItem>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleMenuEdit}>
                    <FormattedMessage
                        id="recipient-list-item.menu.edit-label"
                        description="Recipient list item menu edit label"
                        defaultMessage="Edit"
                    />
                </MenuItem>
                <MenuItem onClick={handleMenuDelete}>
                    <FormattedMessage
                        id="recipient-list-item.menu.delete-label"
                        description="Recipient list item menu delete label"
                        defaultMessage="Delete"
                    />
                </MenuItem>
            </Menu>
        </>
    );
}
