import {PostalCodeFormData} from "./PostalCodeFormData";
import {ValidationErrors} from "../../models/ValidationErrors";
import React, {ChangeEvent} from "react";
import {PnlTextField} from "../PnlTextField/PnlTextField";
import {FormattedMessage} from "react-intl";
import {Grid, MenuItem} from "@mui/material";

interface PostalCodeFormProps {
    data: PostalCodeFormData;
    errors: ValidationErrors;
    onChange: (key: string, value: string) => void;
}

export function PostalCodeForm({ data, errors, onChange }: PostalCodeFormProps) {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.name, event.target.value);
    };

    return (
        <Grid container rowSpacing={0} columnSpacing={1}>
            <Grid item xs={12}>
                <PnlTextField
                    id="city"
                    name="city"
                    variant="standard"
                    maxLength={64}
                    fullWidth
                    label={
                        <FormattedMessage
                            id="postal-code-form.city-label"
                            description="City label"
                            defaultMessage="City"
                        />
                    }
                    helperText={errors.city}
                    error={!!errors.city}
                    value={data.city}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <PnlTextField
                    id="street"
                    name="street"
                    variant="standard"
                    maxLength={64}
                    fullWidth
                    label={
                        <FormattedMessage
                            id="postal-code-form.street-label"
                            description="Street label"
                            defaultMessage="Street"
                        />
                    }
                    helperText={errors.street}
                    error={!!errors.street}
                    value={data.street}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={5}>
                <PnlTextField
                    id="house-number"
                    name="houseNumber"
                    variant="standard"
                    type="number"
                    fullWidth
                    label={<FormattedMessage
                        id="postal-code-form.house-number-label"
                        description="House number label"
                        defaultMessage="House number"
                    />}
                    helperText={errors.houseNumber}
                    error={!!errors.houseNumber}
                    value={data.houseNumber}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={7}>
                <PnlTextField
                    id="house-number-addition"
                    name="houseNumberAddition"
                    variant="standard"
                    select
                    fullWidth
                    label={<FormattedMessage
                        id="postal-code-form.house-number-addition-label"
                        description="House number addition label"
                        defaultMessage="Addition"
                    />}
                    helperText={errors.houseNumberAddition}
                    disabled={!data.houseNumberAdditions.length}
                    error={!!errors.houseNumberAddition}
                    value={data.houseNumberAddition}
                    onChange={handleChange}
                >
                    {data.houseNumberAdditions.map(houseNumberAddition =>
                        <MenuItem key={houseNumberAddition} value={houseNumberAddition}>
                            {houseNumberAddition ||
                                <FormattedMessage
                                    id="postal-code-form.house-number-addition-none-item"
                                    description="House number addition none item"
                                    defaultMessage="No addition"
                                />
                            }
                        </MenuItem>
                    )}
                </PnlTextField>
            </Grid>
        </Grid>
    );
}
