import React from "react";
import {defineMessages, FormattedNumber, useIntl} from "react-intl";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";

import {VideoModel} from "../shared/models/VideoModel";
import {Product} from "../OrderReducer";


const messages = defineMessages({
    previewImageAlt: {
        id: "shared.preview-image-alt",
        description: "Shared preview image alt text",
        defaultMessage: "Video card preview"
    }
});

interface ProductListItemProps {
    product: Product;
    video: Partial<VideoModel>|null;
    currency?: string;
}

export function ProductListItem({product, video, currency="EUR"}: ProductListItemProps) {
    const intl = useIntl();

    return (
        <ListItem
            divider
        >
            <ListItemIcon sx={{mr: 2}}>
                {video && video.target_image &&
                    <img
                        src={video.target_image}
                        alt={intl.formatMessage(messages.previewImageAlt)}
                        style={{maxHeight: '80px'}}
                    />
                }
            </ListItemIcon>
            <ListItemText
                primary={product.name}
                secondary={
                    <>
                        {product.size}
                        <br/>
                        {/* eslint-disable-next-line react/style-prop-object */}
                        <FormattedNumber value={product.price} style="currency" currency={currency} />
                    </>
                }
            />
        </ListItem>

    );
}
