function getItem(key: string): string | null {
    try {
        return localStorage.getItem(key);
    } catch {
        // E.g. Chrome in an iframe with third party cookies disabled
        return null;
    }
}

function setItem(key: string, value: string): void {
    try {
        localStorage.setItem(key, value);
    } catch {
        // E.g. Mobile Safari in private mode
    }
}

function removeItem(key: string): void {
    try {
        localStorage.removeItem(key);
    } catch {
        // E.g. Mobile Safari in private mode
    }
}

export const StorageService = { getItem, setItem, removeItem };
