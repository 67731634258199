import {Dayjs} from "dayjs";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * The printer deadline
 *
 * @var
 */
const deadlineTime = { hour: 6, minute: 0, second: 0, millisecond: 0 };

/**
 * The number of days until delivery by day of the week, grouped by deadline.
 * Delivery duration is determined according to the following schedule:
 *
 * DAY       | BEFORE DL | AFTER DL
 * ---------------------------------
 * Sunday    | Tuesday   | Tuesday
 * Monday    | Tuesday   | Wednesday
 * Tuesday   | Wednesday | Thursday
 * Wednesday | Thursday  | Friday
 * Thursday  | Friday    | Saturday
 * Friday    | Saturday  | Tuesday
 * Saturday  | Tuesday   | Tuesday
 *
 * @var
 */
const deliveryDuration = {
    beforeDeadline : [2, 1, 1, 1, 1, 1, 3],
    afterDeadline  : [2, 2, 2, 2, 2, 4, 3]
};

/**
 * Days of the week on which delivery is available.
 *
 * @var
 */
const deliveryDays = [2, 3, 4, 5, 6];

/**
 * Determine the first available delivery day.
 *
 * @param from
 *     A reference date to start from. Defaults to now.
 * @return
 *     The nearest day on which delivery is available.
 */
export function firstDeliverableDay(from: Dayjs = dayjs()): Dayjs {
    const printerDate = from.tz('Europe/Amsterdam');
    const hasDeadlinePassed = printerDate.isAfter(printerDate.set(deadlineTime));
    const deliveryDays = (hasDeadlinePassed ? deliveryDuration.afterDeadline : deliveryDuration.beforeDeadline)[printerDate.day()];
    return printerDate.add(deliveryDays, 'day').startOf('day');
}

/**
 * Determine the last available delivery day
 *
 * The date is maximized to three months after the provided date, just for pilot purposes.
 *
 * @param from
 */
export function lastDeliverableDay(from: Dayjs = dayjs()): Dayjs {
    return from.add(3, 'month').startOf('day');
}

/**
 * Determine whether delivery is available on the provided day of the week.
 * Does not check if the date is after the next available delivery day (for performance reasons).
 *
 * @param date
 *     The day to check.
 * @return
 *     Whether delivery is available on the day.
 */
export function isDeliverableDay(date: Dayjs): boolean {
    return deliveryDays.includes(date.day());
}
