import {useRef, useState, VideoHTMLAttributes} from "react";

import "./Video.scss";

interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {}

export function Video(props: VideoProps) {
    const ref = useRef<HTMLVideoElement|null>(null);
    const [canPlay, setCanPlay] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        ref.current?.play().catch();
    };

    const handleOnCanPlay = () => {
        setCanPlay(true);
    };

    const handleOnPlay = () => {
        setIsPlaying(true);
    };

    const handleOnPauseOrEnded = () => {
        setIsPlaying(false);
    };

    return (
        <div className="video-wrapper">
            <video
                {...props}
                ref={ref}
                onCanPlay={handleOnCanPlay}
                onPlay={handleOnPlay}
                onPause={handleOnPauseOrEnded}
                onEnded={handleOnPauseOrEnded}
            />
            {canPlay && !isPlaying &&
                <div className="video-controls">
                    <button
                        className="video-play-button"
                        onClick={handlePlay}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                            <path d="m383-310 267-170-267-170v340Zm97 230q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/>
                        </svg>
                    </button>
                </div>
            }
        </div>
    );
}
