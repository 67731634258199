import React, {useEffect, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Box, Button, Stack, SvgIcon, Typography} from "@mui/material";
import {useOrder, useOrderDispatch} from "../OrderContext";
import {useVideo} from "../shared/hooks/useVideo";
import {OrderState} from "../OrderReducer";
import {GA4} from "../shared/services/GA4";


const messages = defineMessages({
    previewImageAlt: {
        id: "shared.preview-image-alt",
        description: "Shared preview image alt text",
        defaultMessage: "Video card preview"
    },
    surveyUrl: {
        id: "done.survey-url",
        description: "Order completion survey URL",
        defaultMessage: "https://survey.survicate.com/d5533ee64bd87de9"
    }
});

const videoFields = ['target_image'];

function FilledCheckCircleIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <circle cx="12" cy="12" r="10"/>
                <polygon fill="#FFFFFF" points="10,17 5,12 6.4,10.6 10,14.2 17.6,6.6 19,8 "/>
            </svg>
        </SvgIcon>
    );
}

export function Done() {
    const order = useOrder();
    const orderDispatch = useOrderDispatch();

    // The order will be reset on the first render, so use a copy for consecutive renders
    const [originalOrder] = useState<OrderState>(order);

    const video = useVideo(originalOrder.videoId, videoFields);
    const intl = useIntl();

    useEffect(() => {
        if (order.id) {
            GA4.sendEvent('purchase', {
                transaction_id: order.id,
                value: (order.product.price * order.recipients.length) / 1.21,
                tax: (order.product.price * order.recipients.length) / 1.21 * 0.21,
                currency: 'EUR',
                items: [{
                    item_name: order.product.name,
                    price: order.product.price / 1.21,
                    quantity: order.recipients.length
                }]
            });

            orderDispatch({ type: 'reset' });
        }
    }, [order, orderDispatch]);

    const surveyUrl = new URL(intl.formatMessage(messages.surveyUrl));
    surveyUrl.searchParams.set('orderId', originalOrder.id ?? 'Unknown');

    return (
        <Stack spacing={2} sx={{px: '20%', pt: '20%'}} alignItems="center">
            { video && video.target_image &&
                <Box sx={{position: "relative", pb: 2}}>
                    <FilledCheckCircleIcon
                        color="success"
                        sx={{fontSize: "4em", position: "absolute", right: 0, top: 0, transform: "translate(50%, -50%)"}}
                    />
                        <img
                            src={video.target_image}
                            alt={intl.formatMessage(messages.previewImageAlt)}
                            style={{width: "100%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"}}
                        />
                </Box>
            }
            <Typography variant="h5" color="secondary" align="center">
                <FormattedMessage
                    id="done.header"
                    description="Done page - Header text"
                    defaultMessage="Thanks for your order!"
                />
            </Typography>
            <Typography align="center">
                <FormattedMessage
                    id="done.message"
                    description="Done page - Message text"
                    defaultMessage="We are going to print and send your {count, plural, =0 {video card} one {video card} other {video cards}}. Please help us improve by taking a short survey."
                    values={{ count: originalOrder.recipients.length }}
                />
            </Typography>
            <Button variant="contained" href={surveyUrl.toString()} target="_blank">
                <FormattedMessage
                    id="done.survey-label"
                    description="Done page - Survey button label"
                    defaultMessage="Start survey"
                />
            </Button>
        </Stack>
    );
}
