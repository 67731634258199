import {AddressModel} from "../models/AddressModel";
import {ApiService} from "./ApiService";


const postalCodeRegex = /^[1-9][0-9]{3}\s*[A-Z]{2}$/i;

function get(search: Partial<AddressModel>, fields?: string[]): Promise<Partial<AddressModel>[]> {

    // Prevent getting a 422 response on an invalid postal code format
    if (search.postalCode && !postalCodeRegex.test(search.postalCode)) {
        return Promise.resolve([]);
    }

    // Only include defined fields
    const query: Record<string, string> = {};
    if (search.city) {
        query.city = search.city;
    }
    if (search.street) {
        query.street = search.street;
    }
    if (search.postalCode) {
        query.postalCode = search.postalCode;
    }
    if (search.houseNumber) {
        query.houseNumber = search.houseNumber.toString();
    }
    if (search.houseNumberAddition) {
        query.houseNumberAddition = search.houseNumberAddition;
    }
    if (search.poBox) {
        query.poBox = search.poBox.toString();
    }

    return ApiService.index<AddressModel>({ path: 'v1/addresses', query, fields });
}

export const AddressService = { get };
